import AgentLoanDetails from './child/AgentLoanDetails'
import React, { useEffect, useState } from 'react'
import $ from 'jquery';
import 'datatables.net-dt/js/dataTables.dataTables.js';
import { Icon } from '@iconify/react/dist/iconify.js';
import { Link } from 'react-router-dom';
import Form1 from '../components/child/Form1';
import Form2 from '../components/child/Form2';
import config from '../config'
import AgentLoanForm1 from './child/AgentLoanForm1';

const AgentloanDetails = () => {
    const [data, setData] = useState()
    const [loan, setLoan] = useState('')
    const [activeTab, setActiveTab] = useState(1);  // Default to tab 1

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const handleOpenDialog = () => {
        setIsDialogOpen(true);
    };
    const handleCloseDialog = () => {
        setIsDialogOpen(false);
    };

    async function fetchData(){
        const Respones = await fetch(`${config.apiUrl}/beta/api/collection/admindefaulter`)
        const json_data = await Respones.json()
        setData(json_data.data)
    }

    // Load the active tab from localStorage on component mount
    useEffect(() => {
        const storedTab = localStorage.getItem('activeTab');
        if (storedTab) {
            setActiveTab(Number(storedTab));  // Convert the stored string to a number
        }

        fetchData();
    }, []);

    // Handle tab change and store the active tab in localStorage
    const handleTabChange = (tabIndex) => {
        setActiveTab(tabIndex);
        localStorage.setItem('activeTab', tabIndex);  // Save the active tab to localStorage
    };

    return (
        <div className="card">
            <div className="card-header d-flex flex-wrap align-items-center justify-content-between gap-3">
                <div className="d-flex flex-wrap align-items-center gap-3">
                    <div className="d-flex align-items-center gap-2">
                        <div className="d-flex justify-content-between mb-3 gap-3">
                            <button
                                className={`btn ${activeTab === 1 ? 'btn-primary' : 'btn-outline-primary'} rounded-3 px-18 py-100 gap-2`}
                                onClick={() => handleTabChange(1)}
                            >
                                Pre Due
                            </button>
                            <button
                                className={`btn ${activeTab === 2 ? 'btn-primary' : 'btn-outline-primary'} rounded-3 px-4 py-2 ml-2`}
                                onClick={() => handleTabChange(2)}
                            >
                                Defaulted
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {activeTab === 1 && <AgentLoanForm1 />}
            {activeTab === 2 && <AgentLoanDetails />}
        </div>
    );
}

export default AgentloanDetails;
