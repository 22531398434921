import React, { useState } from 'react';

const ModalEditRemarks = ({ show, handleClose, handleSave, loanId }) => {
  const [remarks, setRemarks] = useState('');
  const [remarksStatus, setRemarksStatus] = useState(false);

  const handleRemarksChange = (e) => setRemarks(e.target.value);
  const handleRemarksStatusChange = (e) => setRemarksStatus(e.target.checked);

  const handleSubmit = () => {
    handleSave(loanId, remarks, remarksStatus); // Save with loanId
    handleClose(); // Close modal after saving
  };

  return (
    <div
      className={`modal fade ${show ? 'show' : ''}`}
      id="exampleModal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      style={{ display: show ? 'block' : 'none' }} // Control modal visibility
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">Edit Remarks for Loan ID: {loanId}</h5>
            <button type="button" className="btn-close" onClick={handleClose}></button>
          </div>
          <div className="modal-body">
            <form>
              <div className="mb-3">
                <label htmlFor="remarks" className="form-label">Remarks</label>
                <input
                  type="text"
                  className="form-control"
                  id="remarks"
                  value={remarks}
                  onChange={handleRemarksChange}
                />
              </div>

              <div className="mb-3 form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="remarksStatus"
                  checked={remarksStatus}
                  onChange={handleRemarksStatusChange}
                />
                <label className="form-check-label" htmlFor="remarksStatus">
                  Remarks Status
                </label>
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={handleClose}>Close</button>
            <button type="button" className="btn btn-primary" onClick={handleSubmit}>Save Changes</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalEditRemarks;
