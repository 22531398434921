import React, { useEffect, useRef, useState } from 'react'
import { Icon } from '@iconify/react/dist/iconify.js'
import config from '../../config';


const UnitCountFour = () => {
    const [data, setData] = useState()
    const didFetchData = useRef(false); // Ref to ensure fetchData is called once
  const token =  localStorage.getItem('access_token')
  console.log(token,"==============================")

    async function fetchData() {
        try {
            const response = await fetch(`${config.apiUrl}/beta/api/collection/overalldashboard`, {
                method: 'GET',  // or 'POST' depending on the request type
                headers: {
                  'Authorization': token,  // Replace with your actual token
                  'Content-Type': 'application/json'  // Include this if you're sending JSON data
                }
              });          const json_data = await response.json();
          setData(json_data);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
    
useEffect(()=>{
fetchData()
},[])


  

  return (
    <>
           {/* Dashboard Widget Start */}
           <div className="col-xxl-3 col-sm-6">
           <div className="card px-24 py-16 shadow-none radius-8 border h-100 bg-gradient-start-3">
               <div className="card-body p-0">
                   <div className="d-flex flex-wrap align-items-center justify-content-between gap-1 mb-8">
                       <div className="d-flex align-items-center">
                           <div className="w-64-px h-64-px radius-16 bg-base-50 d-flex justify-content-center align-items-center me-20">
                               <span className="mb-0 w-40-px h-40-px bg-primary-600 flex-shrink-0 text-white d-flex justify-content-center align-items-center radius-8 h6 mb-0">
                                   <Icon
                                       icon="flowbite:users-group-solid"
                                       className="icon"
                                   />
                               </span>
                           </div>
                           <div>
                               <span className="mb-2 fw-medium text-secondary-light text-md">
                                   Total Extention Paid on time
                               </span>
                               <h6 className="fw-semibold my-1">{data?.total_extension_close}/{data?.close_paid_on}</h6>
                       
                           </div>
                       </div>
                   </div>
               </div>
           </div>
       </div>
       {/* Dashboard Widget End */}
       {/* Dashboard Widget Start */}
       <div className="col-xxl-3 col-sm-6">
           <div className="card px-24 py-16 shadow-none radius-8 border h-100 bg-gradient-start-2">
               <div className="card-body p-0">
                   <div className="d-flex flex-wrap align-items-center justify-content-between gap-1 mb-8">
                       <div className="d-flex align-items-center">
                           <div className="w-64-px h-64-px radius-16 bg-base-50 d-flex justify-content-center align-items-center me-20">
                               <span className="mb-0 w-40-px h-40-px bg-purple flex-shrink-0 text-white d-flex justify-content-center align-items-center radius-8 h6 mb-0">
                                   <Icon
                                       icon="solar:wallet-bold"
                                       className="text-white text-2xl mb-0"
                                   />
                               </span>
                           </div>
                           <div>
                               <span className="mb-2 fw-medium text-secondary-light text-md">
                                   Total Paid on time
                               </span>
                               <h6 className="fw-semibold my-1">{data?.close_paid_on}/{data?.Paid_On}</h6>
                               {/* <p className="text-sm mb-0">
                                   Increase by {" "}
                                   <span className="bg-danger-focus px-1 rounded-2 fw-medium text-danger-main text-sm">
                                       -200
                                   </span>{" "}
                                   this week
                               </p> */}
                           </div>
                       </div>
                   </div>
               </div>
           </div>
       </div>
       {/* Dashboard Widget End */}
       {/* Dashboard Widget Start */}
       <div className="col-xxl-3 col-sm-6">
           <div className="card px-24 py-16 shadow-none radius-8 border h-100 bg-gradient-start-5">
               <div className="card-body p-0">
                   <div className="d-flex flex-wrap align-items-center justify-content-between gap-1 mb-8">
                       <div className="d-flex align-items-center">
                           <div className="w-64-px h-64-px radius-16 bg-base-50 d-flex justify-content-center align-items-center me-20">
                               <span className="mb-0 w-40-px h-40-px bg-red flex-shrink-0 text-white d-flex justify-content-center align-items-center radius-8 h6 mb-0">
                                   <Icon
                                       icon="fa6-solid:file-invoice-dollar"
                                       className="text-white text-2xl mb-0"
                                   />
                               </span>
                           </div>
                           <div>
                               <span className="mb-2 fw-medium text-secondary-light text-md">
                                   Defaulted Loan (Amount Principal)
                               </span>
                               <h6 className="fw-semibold my-1">{data?.total_amount}</h6>
                               {/* <p className="text-sm mb-0">
                                   Increase by {" "}
                                   <span className="bg-success-focus px-1 rounded-2 fw-medium text-success-main text-sm">
                                       +200
                                   </span>
                                   this week
                               </p> */}
                           </div>
                       </div>
                   </div>
               </div>
           </div>
       </div>
       {/* Dashboard Widget End */}
       {/* Dashboard Widget Start */}
       <div className="col-xxl-3 col-sm-6">
           <div className="card px-24 py-16 shadow-none radius-8 border h-100 bg-gradient-start-4">
               <div className="card-body p-0">
                   <div className="d-flex flex-wrap align-items-center justify-content-between gap-1 mb-8">
                       <div className="d-flex align-items-center">
                           <div className="w-64-px h-64-px radius-16 bg-base-50 d-flex justify-content-center align-items-center me-20">
                               <span className="mb-0 w-40-px h-40-px bg-success-main flex-shrink-0 text-white d-flex justify-content-center align-items-center radius-8 h6 mb-0">
                                   <Icon
                                       icon="streamline:bag-dollar-solid"
                                       className="icon"
                                   />
                               </span>
                           </div>
                           <div>
                               <span className="mb-2 fw-medium text-secondary-light text-md">
                                   Loan amount be Recovered
                               </span>
                               <h6 className="fw-semibold my-1">{Number(data?.total_payable_amount).toFixed(2)}</h6>
                               {/* <p className="text-sm mb-0">
                                   Increase by {" "}
                                   <span className="bg-success-focus px-1 rounded-2 fw-medium text-success-main text-sm">
                                       +200
                                   </span>{" "}
                                   this week
                               </p> */}
                           </div>
                       </div>
                   </div>
               </div>
           </div>
       </div>
       {/* Dashboard Widget End */}
       </>
  )
}

export default UnitCountFour