import ReactApexChart from 'react-apexcharts'
import { Bar } from 'react-chartjs-2';
import "./customeDatePick.css"
import {
    Chart as ChartJS,
    BarElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend,
} from 'chart.js';
import { useEffect, useState } from 'react';
import DateRangeSelector from './DateRangeSelector ';
import config from '../../config';

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);
const DoubleChartCollect1 = () => {
    let [selectedOptions, setSelectedOptions] = useState(2024);
    const [years, setYears] = useState([]);
    const [data1, setData1] = useState({month:[], totalLoanAmount:[],paidAmountSum:[],outstandingAmount:[]  })


    const handleSelectChanges = (event) => {
        const value = event.target.value;
        setSelectedOptions(Number(value));

  
      };
      async function fetchDate(){
        const token = localStorage.getItem('access_token')
        const response = await fetch(`${config.apiUrl}/beta/api/collection/bar_chart?year=${selectedOptions}`,{
            method: "GET",
            headers:{
              'Authorization': token,  // Replace with your actual token
              'Content-Type': 'application/json'  // Include this if you're sending JSON data
            }

        })
    const json_data = await response.json() 
    setData1({month: json_data?.data?.months, totalLoanAmount: json_data?.data?.totalLoanAmount, paidAmountSum: json_data?.data?.paidAmountSum,outstandingAmount: json_data?.data?.outstandingAmount})
    }

      useEffect(()=>{
        fetchDate()

        const currentYear = new Date().getFullYear();

        const yearsArray = [];
        for (let year = 2018; year <= currentYear; year++) {
          yearsArray.push(year);
        }
        setYears(yearsArray);
      },[selectedOptions])

  
    const data = {
        labels: data1.month,
        datasets: [
            {
                label: 'Total Amount',
                data: data1.totalLoanAmount,
                backgroundColor: 'RGB(255, 120, 30)',
                borderColor: 'rgba(54, 162, 235, 1)',
                borderWidth: 1,
            },
            {
                label: 'Total Paid Amount',
                data: data1.paidAmountSum,
                backgroundColor: 'RGBA(150, 150, 255, 0.8)',
                borderColor: 'rgba(255, 99, 132, 1)',
                borderWidth: 1,
            },
            {
                label: 'Total Outstanding Amount',
                data: data1.outstandingAmount,
                backgroundColor: 'RGBA(150, 150, 255, 0.8)',
                borderColor: 'rgba(255, 99, 132, 1)',
                borderWidth: 1,
            },
        ],
    };
    
    // Chart options
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            tooltip: {
                enabled: true,
            },
        },
        scales: {
            x: {
                stacked: false,
            },
            y: {
                beginAtZero: true,
            },
        },
    };

  return (
    <div className="col-xxl-12">
    <div className="card h-100 p-0">
        <div className="card-header border-bottom bg-base py-18 px-36">
            <h6 className="text-lg fw-semibold mb-0">Loan collected vs Assigned</h6>
            <div className="d-flex align-items-center flex-wrap gap-3">
    
                        <div className="d-flex align-items-center flex-wrap gap-3">
                        <select       value={selectedOptions}
        onChange={handleSelectChanges} className="form-select form-select-sm w-auto bg-base border text-secondary-light" defaultValue="">
                                <option value="" disabled>
                                    Year
                                </option>
                                {years.map((year) => (
        <option key={year} value={year}>
          {year}
        </option>
      ))}

                            </select>
                        </div>
                        {/* <div className="d-flex align-items-center flex-wrap gap-3">
                    <select className="form-select form-select-sm w-auto bg-base border text-secondary-light" defaultValue="">
                                <option value="" disabled>
                                    Select Date
                                </option>
                                <option value="Due_date">Loan Due date</option>
                                <option value="loan_date">Loan Date</option>
                            </select>
                        </div> */}

                        </div>
        </div>

        <div className="card-body p-24">

        <Bar data={data} options={options} />
        </div>
    </div>
</div>  )
}

export default DoubleChartCollect1