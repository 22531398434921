import { Icon } from '@iconify/react/dist/iconify.js';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import config from '../config';
import { Loader } from './child/Loader';
import { DateRangePicker } from 'react-date-range';


const UsersListLayer = () => {
    const [userData , setuseData] = useState()
    const [totalPages , setTotalPages] = useState()
    const [currentPage, setCurrentPage] = useState(1)
    const individual = useSelector((state)=>  state.Individual.individual_role)
    const [loading, setLoading] = useState(true); // Loading state
    const current_id = useSelector((state)=> state?.user?.currentUser?._id)
    const [formData, setFormData] = useState({});
    const [openDate, setOpenDate] = useState(true)
    const [selectedOptions, setSelectedOptions] = useState('')

  const [selectionRange, setSelectionRange] = useState({
    startDate: '',
    endDate: '',
    key: "selection",
  });

  const [showCalendar, setShowCalendar] = useState(false); // State to toggle calendar visibility


    const PAGE_LIMIT = 5; // Number of pages to show before and after current page

const handlePageClick = (page) => {
    if (page < 1 || page > totalPages) return;
    setCurrentPage(page)
    fetchData();
};
const handleApply = () => {
    setShowCalendar(false); // Close the calendar after applying the date range
  };
  const handleSelectChanges = (event) => {
    const value = event.target.value;
    setSelectedOptions(value);
    if (value === 'createdAt') {
      setOpenDate(false);
    } else {
      setOpenDate(true);
    }
  };
const handleSelects= (ranges) => {
    setSelectionRange(ranges.selection); // Update the selectionRange state with new date range
    console.log(selectionRange.endDate,"==============================lll")

  };

const toggleCalendar = () => {
    setShowCalendar(!showCalendar); // Toggle the calendar visibility
  };

const handleChanges = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value.trim() });
    console.log(formData,"===============ff===============")
  };

const getPageNumbers = () => {
    const pages = [];
    const leftLimit = Math.max(1, currentPage - Math.floor(PAGE_LIMIT / 2));
    const rightLimit = Math.min(totalPages, currentPage + Math.floor(PAGE_LIMIT / 2));

    if (leftLimit > 1) {
        pages.push(1);
        if (leftLimit > 2) pages.push('...'); // Ellipsis for skipped pages
    }

    for (let i = leftLimit; i <= rightLimit; i++) {
        pages.push(i);
    }

    if (rightLimit < totalPages) {
        if (rightLimit < totalPages - 1) pages.push('...'); 
        pages.push(totalPages);
    }

    return pages;
};
const handleFile = async (e)=>{
  const token = localStorage.getItem('access_token')
    let Respones = await fetch(`${config.apiUrl}/beta/api/collection/admin/${current_id}?excel=true`,{
      method:'GET',
      headers: {
        'Content-Type': 'application/json', // Specify the content type
        'Authorization': token,  // Replace with your actual token

    },

    })
    const json_data = await Respones.json()

    const binaryString = window.atob(json_data.body);  
    const byteArray = new Uint8Array(binaryString.length); 
    for (let i = 0; i < binaryString.length; i++) {
      byteArray[i] = binaryString.charCodeAt(i);
    }

    const blob = new Blob([byteArray], { type: 'application/octet-stream' }); 
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = 'user_details.xlsx'; 
    link.click();

    window.URL.revokeObjectURL(url);



}

    async function fetchData(){
      const token = localStorage.getItem('access_token')
        const data = await fetch(`${config.apiUrl}/beta/api/collection/admin/${current_id}?pages=${currentPage}&email=${formData.email}&username=${formData.username}&status=${formData.status}&role=${formData.role}&start=${selectionRange.startDate}&end=${selectionRange.endDate}`,{
          method:'GET',
          headers: {
            'Content-Type': 'application/json', // Specify the content type
            'Authorization': token,  // Replace with your actual token
    
        },          
        })
        const jsonData = await data.json()
        if(data.ok){
            setLoading(false)
        }
        setuseData(jsonData.data)
        setTotalPages(jsonData.totalPage)

    }
    useEffect(()=>{
        fetchData()

    },[currentPage,formData])
  return (
    <> 
    <Loader loading={loading}/>   
    <div className="card h-100 p-0 radius-12">
    <div className="card-header border-bottom bg-base py-16 px-24 d-flex align-items-center flex-wrap gap-3 justify-content-between">
  <div className="row w-100">
    {/* Name Input */}
    <div className="col-12 col-md-6 col-lg-3 mb-2">
      <label className="form-label" htmlFor="LoanId">Name</label>
      <input
        className="form-control w-100"
        id="username"
        type="text"
        placeholder="Name"
        onChange={handleChanges}
      />
    </div>

    {/* Email Input */}
    <div className="col-12 col-md-6 col-lg-3 mb-2">
      <label className="form-label" htmlFor="LoanId">Email</label>
      <input
        className="form-control w-100"
        id="email"
        type="text"
        placeholder="Email"
        onChange={handleChanges}
      />
    </div>

    {/* Status Dropdown */}
    <div className="col-12 col-md-6 col-lg-3 mb-2">
      <label className="form-label" htmlFor="LoanId">Status</label>
      <select
        id="status"
        className="form-select form-select-sm w-100 ps-12 py-6 radius-12 h-40-px"
        defaultValue="Select Status"
        onChange={handleChanges}
      >
        <option value="Select Status" disabled>
          Select Status
        </option>
        <option value="true">Active</option>
        <option value="false">Inactive</option>
      </select>
    </div>

    {/* Role Dropdown */}
    <div className="col-12 col-md-6 col-lg-3 mb-2">
      <label className="form-label" htmlFor="LoanId">Role</label>
      <select
        id="role"
        className="form-select form-select-sm w-100 ps-12 py-6 radius-12 h-40-px"
        defaultValue="Select Role"
        onChange={handleChanges}
      >
        <option value="Select Status" disabled>
          Select Role
        </option>
        <option value="SuperAdmin">Super Admin</option>
        <option value="StucredAgencies">Stucred Agencies</option>
        <option value="StucredAgent">Stucred Agent</option>

        <option value="admin">Admin</option>
        <option value="Agencies">Agencies</option>
        <option value="Agencies">Agent</option>




      </select>
    </div>

    {/* Date Range Picker */}
    <div className="col-12 col-md-6 col-lg-3 mb-2">
  <label className="form-label" htmlFor="LoanId">Created At</label>
  {openDate && (
    <div style={{ position: "relative", width: "100%" }}>
      <button
        onClick={toggleCalendar}
        style={{
          padding: "7px 6px",
          border: "1px solid #ccc",
          borderRadius: "5px",
          width: "100%",
          textAlign: "left",
          backgroundColor: "#fff",
          cursor: "pointer",
        }}
      >
        {selectionRange.startDate && selectionRange.endDate
          ? `${selectionRange.startDate.toLocaleDateString()} - ${selectionRange.endDate.toLocaleDateString()}`
          : "Select Date Range"}
      </button>

      {showCalendar && (
        <div
          style={{
            position: "absolute",
            top: "50px",
            left: "0",
            zIndex: 999,
            backgroundColor: "#fff",
            padding: "10px",
            border: "1px solid #ccc",
            borderRadius: "5px",
          }}
        >
          <DateRangePicker
            ranges={[selectionRange]}
            onChange={handleSelects}
            showDateDisplay={false}
          />
          <div style={{ marginTop: "10px", textAlign: "center" }}>
            <button
              onClick={handleApply}
              style={{
                padding: "5px 7px",
                border: "1px solid #007BFF",
                borderRadius: "5px",
                backgroundColor: "#007BFF",
                color: "#fff",
                cursor: "pointer",
              }}
            >
              Apply
            </button>
          </div>
        </div>
      )}
    </div>
  )}

 
</div>
<div className="col-12 col-md-6 col-lg-3 mb-2">
<label>{''}</label>

<div className="row mt-2">

    <div className="col">
      <button className="btn btn-sm btn-primary w-100" onClick={fetchData}>
        <i className="ri-search-line" /> Search
      </button>
    </div>
  </div>
</div>
    
  </div>

  {/* Action Buttons */}
  <div className="d-flex flex-wrap align-items-center gap-3 ms-auto">
  <Link
    to="/add-user"
    className="btn btn-primary text-sm btn-sm px-12 py-12 radius-8 d-flex align-items-center gap-2"
  >
    <Icon icon="ic:baseline-plus" className="icon text-xl line-height-1" />
    Add New User
  </Link>
  {individual?.user_list?.export_access === true && (
    <button className="btn btn-sm btn-primary-600" onClick={handleFile}>
      <i className="ri-add-line" /> Download XLSX
    </button>
  )}
</div>

</div>

    <div className="card-body p-24">
        <div className="table-responsive scroll-sm">
            <table className="table bordered-table sm-table mb-0">
                <thead>
                    <tr>
                 
                        <th scope="col">Name</th>
                        <th scope="col">Email</th>
                        <th scope="col">State</th>
                        <th scope="col">City</th>
                        <th scope="col">Role</th>
                        <th scope="col">Status</th>
                        <th scope="col" className="text-center">
                            Login status
                        </th>
                        <th scope="col">Created</th>

                        <th scope="col">Action</th>

                 
                    </tr>
                </thead>
                <tbody>
{userData?.map((data, index)=>(
         <tr key={index}>
                   
         <td>
             <div className="d-flex align-items-center">
                 <img
                     src={data.profilePicture}
                     alt="Wowdash"
                     className="w-40-px h-40-px rounded-circle flex-shrink-0 me-12 overflow-hidden"
                 />
                 <div className="flex-grow-1">
                     <span className="text-md mb-0 fw-normal text-secondary-light">
                         {data.username}
                     </span>
                 </div>
             </div>
         </td>
         <td>
             <span className="text-md mb-0 fw-normal text-secondary-light">
                {data.email}
             </span>
         </td>
         <td>{data.state}</td>
         <td>{data.city}</td>
         <td>{data.role}</td>

         <td className="text-center">
                                            <span
                                                className={`px-24 py-4 radius-4 fw-medium text-sm ${data?.status
                                                    ? "bg-success-focus text-success-600 border border-success-main"
                                                    : "bg-danger-focus text-danger-600 border border-danger-main"
                                                    }`}
                                            >
                                                {data.status ? "Active" : "Inactive"}
                                            </span>
                                        </td>
                                        <td className="text-center">
                                            <span
                                                className={`px-24 py-4 radius-4 fw-medium text-sm ${data?.isloggedIn
                                                    ? "bg-success-focus text-success-600 border border-success-main"
                                                    : "bg-danger-focus text-danger-600 border border-danger-main"
                                                    }`}
                                            >
                                                {data.isloggedIn ? "Active" : "Inactive"}
                                            </span>
                                        </td>
                                        <td>{new Date(data.createdAt).toLocaleString()}</td>
{individual?.user_list?.readwrite == true && (
         <td className="text-center">
             <div className="d-flex align-items-center gap-10 justify-content-center">
               
                 <NavLink
                 to ={`/view-profile?${data?._id}`}
                     className="bg-success-focus text-success-600 bg-hover-success-200 fw-medium w-40-px h-40-px d-flex justify-content-center align-items-center rounded-circle"
                 >
                     <Icon icon="lucide:edit" className="menu-icon" />
                 </NavLink>
            
             </div>
         </td>
         )}
     </tr>
))}
           
              
                </tbody>
            </table>
        </div>
        <div className="d-flex flex-wrap align-items-center justify-content-between gap-2 mt-24">
            <span>
                Showing {(currentPage - 1) * 10 + 1} to {Math.min(currentPage * 10, totalPages * 10)} of {totalPages * 10} entries
            </span>
            <ul className="pagination d-flex flex-wrap align-items-center gap-2 justify-content-center">
                {/* Previous Button */}
                <li className="page-item">
                    <Link
                        className={`page-link text-secondary-light fw-medium radius-4 border-0 px-10 py-10 d-flex align-items-center justify-content-center h-32-px me-8 w-32-px ${currentPage === 1 ? 'bg-base' : ''}`}
                        to="#"
                        onClick={() => handlePageClick(currentPage - 1)}
                        disabled={currentPage === 1}
                    >
                        <Icon icon="ep:d-arrow-left" className="text-xl" />
                    </Link>
                </li>

                {/* Page Numbers */}
                {getPageNumbers().map((page, index) => (
                    <li key={index} className="page-item">
                        {/* Render page number or ellipsis */}
                        <Link
                            className={`page-link fw-medium radius-4 border-0 px-10 py-10 d-flex align-items-center justify-content-center h-32-px me-8 w-32-px ${
                                page === currentPage ? 'bg-primary-600 text-white' : 'bg-primary-50 text-secondary-light'
                            }`}
                            to="#"
                            onClick={() => page !== '...' && handlePageClick(page)}
                        >
                            {page}
                        </Link>
                    </li>
                ))}

                {/* Next Button */}
                <li className="page-item">
                    <Link
                        className={`page-link text-secondary-light fw-medium radius-4 border-0 px-10 py-10 d-flex align-items-center justify-content-center h-32-px me-8 w-32-px ${currentPage === totalPages ? 'bg-base' : ''}`}
                        to="#"
                        onClick={() => handlePageClick(currentPage + 1)}
                        disabled={currentPage === totalPages}
                    >
                        <Icon icon="ep:d-arrow-right" className="text-xl" />
                    </Link>
                </li>
            </ul>
        </div>
    </div>
</div>
</>

  )
}

export default UsersListLayer