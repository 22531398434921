import React, { useEffect, useRef, useState } from 'react'
import $ from 'jquery';
import 'datatables.net-dt/js/dataTables.dataTables.js';
import { Icon } from '@iconify/react/dist/iconify.js';
import { Link } from 'react-router-dom';
import InputDialog from './InputDialog';
import { useSelector } from 'react-redux';
import config from '../../config';
import { Loader } from './Loader';
const Cashfreelink = () => {
  const [data, setData] = useState()
  const[loan, setLoan] = useState('undefined')
  const [loan_data, setloanData] = useState()
  const [selection, setSelection] = useState()
  const [totalPages , setTotalPages] = useState()
  const [currentPage, setCurrentPage] = useState(1)
  const [loading, setLoading] = useState(true); // Loading state

  const individual = useSelector((state)=>  state?.Individual?.individual_role)

  const serviceSelect = useRef(null);
  const dailySelect = useRef(null);

const handlechange = (e)=>{
  setSelection(e.target.value)

}
const PAGE_LIMIT = 5; // Number of pages to show before and after current page

const handlePageClick = (page) => {
  if (page < 1 || page > totalPages) return;
  setCurrentPage(page)
  fetchData();
};

const getPageNumbers = () => {
  const pages = [];
  const leftLimit = Math.max(1, currentPage - Math.floor(PAGE_LIMIT / 2));
  const rightLimit = Math.min(totalPages, currentPage + Math.floor(PAGE_LIMIT / 2));

  if (leftLimit > 1) {
      pages.push(1);
      if (leftLimit > 2) pages.push('...'); // Ellipsis for skipped pages
  }

  for (let i = leftLimit; i <= rightLimit; i++) {
      pages.push(i);
  }

  if (rightLimit < totalPages) {
      if (rightLimit < totalPages - 1) pages.push('...'); 
      pages.push(totalPages);
  }

  return pages;
};
  async function fetchData(){
    const token = localStorage.getItem('access_token')
      setLoading(true)

      const Respones = await fetch(`${config.apiUrl}/beta/api/collection/logs/cash_free?&pages=${currentPage}`,{
        method: 'GET',
        headers: {
          'Authorization': token,  // Replace with your actual token
          'Content-Type': 'application/json'  // Include this if you're sending JSON data
        },
      })
      if(loan != 'undefined'){
          const loan_data = await fetch(`${config.apiUrl}/beta/api/collection/get_waiver/${loan}?percInterest=${Number(dailySelect.current.value)}&serperInterest=${Number(serviceSelect.current.value)}`,{
            method: 'GET',
            headers: {
              'Authorization': token,  // Replace with your actual token
              'Content-Type': 'application/json'  // Include this if you're sending JSON data
            },
          })
          const json_data = await loan_data.json()
          setloanData(json_data.data)
  
  
          }
      const json_data = await Respones.json()
      if(Respones.ok){
          setLoading(false)
      }
      setTotalPages(json_data.totalPages)
      setData(json_data.data)
      
  }


  
  useEffect(()=>{
      if (loan) {
          fetchData();  // Only fetch when `loan` is defined

      }
      },[loan,serviceSelect,dailySelect,selection,currentPage])
  const handlecashfree = (event) =>{
      fetchData()
      const serviceValue = serviceSelect.current.value;
      const dailyValue = dailySelect.current.value;

  }
  const handleFile = async (e)=>{
    const token = localStorage.getItem('access_token')
      let Respones = await fetch(`${config.apiUrl}/beta/api/collection/mapped_collection?excel=true`,{
        method: 'GET',
        headers: {
          'Authorization': token,  // Replace with your actual token
          'Content-Type': 'application/json'  // Include this if you're sending JSON data
        },
      })
      const json_data = await Respones.json()

      const binaryString = window.atob(json_data.body);  
      const byteArray = new Uint8Array(binaryString.length); 
      for (let i = 0; i < binaryString.length; i++) {
        byteArray[i] = binaryString.charCodeAt(i);
      }
  
      const blob = new Blob([byteArray], { type: 'application/octet-stream' }); 
      const url = window.URL.createObjectURL(blob);
  
      const link = document.createElement('a');
      link.href = url;
      link.download = 'loan_mapping.xlsx'; 
      link.click();
  
      window.URL.revokeObjectURL(url);



  }
 const  handlecashfreelink = async(loan_amount, loan_id)=>{
  let postData = {
      loan_id: loan_id,
      link_amount: loan_amount
  }
  const token = localStorage.getItem('access_token')
  const response = await fetch(`${config.apiUrl}/beta/api/collection/cashfree_link`, {
      method: 'POST',               // Set the request method to POST
      headers: {
        'Authorization': token,  // Replace with your actual token
        'Content-Type': 'application/json'  // Include this if you're sending JSON data
      },
      body: JSON.stringify(postData) // Convert the data to a JSON string
  });
  }

  return (
    <> 
     {     loading ? (
        <Loader loading={loading}/>
      ) : (
        <>
    
       <div className="card-header d-flex flex-wrap align-items-center justify-content-between gap-3">

<div className="d-flex flex-wrap align-items-center gap-3">
<select id='status' className="form-select form-select-sm w-auto" defaultValue="Select Days" onChange={handlechange}>
        <option value="Select Days" disabled>
            Select Days
        </option>
        <option value="0">0</option>
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3">3</option>
        <option value="4">4</option>
        <option value="5">5</option>
        <option value="6">6</option>
        <option value="7">7</option>
        <option value="All">All</option>

    </select>
    <div className="icon-field">
        <input
            type="text"
            name="#0"
            className="form-control form-control-sm w-auto"
            placeholder="Search"
        />
        <span className="icon">
            <Icon icon="ion:search-outline" />
        </span>
    </div>


</div>
<div className="d-flex flex-wrap align-items-center gap-3">

{individual?.loan_details?.export_access === true && (

<button className="btn btn-sm btn-primary-600" onClick={handleFile}>
        <i className="ri-add-line" /> Download XLSX
    </button>
)}

</div>
</div>

<div className="card-body">
<div style={{ overflowX: 'auto', maxHeight: '750px', overflowY: 'auto' }}>
<table className="table bordered-table mb-0">
    <thead>
        <tr>

            <th scope="col">Loan Id</th>
            <th scope="col">Link Amount</th>
            <th scope="col">Link Amount Paid</th>
            <th scope="col">Customer name</th>
            <th scope="col">Customer Mobile</th>
            <th scope="col">Customer Email</th>
            <th scope="col">Link created At</th>
            <th scope="col">Link Status</th>
            <th scope="col">Link Url</th>
            <th scope="col">CreatedAt</th>
            <th scope="col">Created By</th>


        </tr>
    </thead>
    <tbody>
    {data?.map((data,index)=>(

<tr key={index}>

<td>
<Link to="#" className="text-primary-600">
{data?.originalData?.link_notes?.loan_id}
</Link>
</td>
<td>
<div className="d-flex align-items-center">

<h6 className="text-md mb-0 fw-medium flex-grow-1">
    {data?.originalData.link_amount}
</h6>
</div>
</td>
<td>{data?.originalData?.link_amount_paid}</td>
<td>{data?.originalData?.customer_details?.customer_name}</td>
<td>{data?.originalData.customer_details?.customer_phone}</td>
<td>{data?.originalData?.customer_details?.customer_email}</td>
<td>{new Date(data?.originalData?.link_created_at).toLocaleDateString()}</td>

<td>{data?.originalData?.link_status}</td>
<td>{data?.originalData?.link_url}</td>
<td>{new Date(data?.modifiedAt).toLocaleString()}</td>

<td>{data?.modifiedBy}</td>

</tr>
    ))}

      
    </tbody>
</table>

</div>
<div className="d-flex flex-wrap align-items-center justify-content-between gap-2 mt-24">
            <span>
                Showing {(currentPage - 1) * 10 + 1} to {Math.min(currentPage * 10, totalPages * 10)} of {totalPages * 10} entries
            </span>
            <ul className="pagination d-flex flex-wrap align-items-center gap-2 justify-content-center">
                {/* Previous Button */}
                <li className="page-item">
                    <Link
                        className={`page-link text-secondary-light fw-medium radius-4 border-0 px-10 py-10 d-flex align-items-center justify-content-center h-32-px me-8 w-32-px ${currentPage === 1 ? 'bg-base' : ''}`}
                        to="#"
                        onClick={() => handlePageClick(currentPage - 1)}
                        disabled={currentPage === 1}
                    >
                        <Icon icon="ep:d-arrow-left" className="text-xl" />
                    </Link>
                </li>

                {/* Page Numbers */}
                {getPageNumbers().map((page, index) => (
                    <li key={index} className="page-item">
                        {/* Render page number or ellipsis */}
                        <Link
                            className={`page-link fw-medium radius-4 border-0 px-10 py-10 d-flex align-items-center justify-content-center h-32-px me-8 w-32-px ${
                                page === currentPage ? 'bg-primary-600 text-white' : 'bg-primary-50 text-secondary-light'
                            }`}
                            to="#"
                            onClick={() => page !== '...' && handlePageClick(page)}
                        >
                            {page}
                        </Link>
                    </li>
                ))}

                {/* Next Button */}
                <li className="page-item">
                    <Link
                        className={`page-link text-secondary-light fw-medium radius-4 border-0 px-10 py-10 d-flex align-items-center justify-content-center h-32-px me-8 w-32-px ${currentPage === totalPages ? 'bg-base' : ''}`}
                        to="#"
                        onClick={() => handlePageClick(currentPage + 1)}
                        disabled={currentPage === totalPages}
                    >
                        <Icon icon="ep:d-arrow-right" className="text-xl" />
                    </Link>
                </li>
            </ul>
        </div>

</div>
</>
)}
<div
className="modal fade"
id="exampleModal"
tabIndex={-1}
aria-labelledby="exampleModalLabel"
aria-hidden="true"
>
<div className="modal-dialog modal-lg modal-dialog modal-dialog-centered">
    <div className="modal-content radius-16 bg-base">
        <div className="modal-header py-16 px-24 border border-top-0 border-start-0 border-end-0">
            <h1 className="modal-title fs-5" id="exampleModalLabel">
                Generate Link
            </h1>
            <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
            />
        </div>
        <div className="modal-body p-24">
            <form action="#">
            <div className="d-flex flex-wrap align-items-center gap-3 mb-5">
            <p className="fw-semibold text-gray-500">Service  %:</p>

            <select id='service' className="form-select form-select-sm w-auto" defaultValue="service"  
                ref={serviceSelect}  // Reference to the DOM element
                onChange={handlecashfree} 
                              >
        <option value="service" disabled>
            Select Service charge discount
        </option>
        <option value="0">0%</option>
        <option value="25">25%</option>
        <option value="50">50%</option>
        <option value="75">75%</option>
        <option value="100">100%</option>

    </select>
    <p className="fw-semibold text-gray-500">Daily  %:</p>

    <select id='daily' className="form-select form-select-sm w-auto" defaultValue="daily" ref={dailySelect}   onChange={handlecashfree}  >
        <option value="daily" disabled>
            Select Defaulted Loans
        </option>
        <option value="0">0%</option>

        <option value="25">25%</option>
        <option value="50">50%</option>
        <option value="75">75%</option>
        <option value="100">100%</option>
    </select>
    </div>
                <div className="row">
                <div className="d-flex align-items-center justify-content-between border border-gray-300 bg-gray-50 p-2 rounded shadow-sm">
<p className="fw-semibold text-gray-500">Defaulting in days:</p>
<p className="text-right text-gray-600">{loan_data?.no_of_days_past_due}</p>
</div>
<div className="d-flex align-items-center justify-content-between border border-gray-300 bg-gray-50 p-2 rounded shadow-sm">
<p className="fw-semibold text-gray-500">Total Service Charge Fee:</p>
<p className="text-right text-gray-600">{loan_data?.serviceChargeFee}</p>
</div>
<div className="d-flex align-items-center justify-content-between border border-gray-300 bg-gray-50 p-2 rounded shadow-sm">
<p className="fw-semibold text-gray-500">Total Daily Charge Fee:</p>
<p className="text-right text-gray-600">{loan_data?.dailyLateFee}</p>
</div>
<div className="d-flex align-items-center justify-content-between border border-gray-300 bg-gray-50 p-2 rounded shadow-sm">
<p className="fw-semibold text-gray-500">Total Penalty:</p>
<p className="text-right text-gray-600">{loan_data?.totalPenalty}</p>
</div>
<div className="d-flex align-items-center justify-content-between border border-gray-300 bg-gray-50 p-2 rounded shadow-sm">
<p className="fw-semibold text-gray-500">Total Amount Payable:</p>
<p className="text-right text-gray-600">{loan_data?.totalAmountPayable}</p>
</div>
<div className="d-flex align-items-center justify-content-between border border-gray-300 bg-gray-50 p-2 rounded shadow-sm">
<p className="fw-semibold text-gray-500">Service Charge Payable:</p>
<p className="text-right text-gray-600">{loan_data?.service_waived}</p>
</div>
<div className="d-flex align-items-center justify-content-between border border-gray-300 bg-gray-50 p-2 rounded shadow-sm">
<p className="fw-semibold text-gray-500">Daily Charge Payable:</p>
<p className="text-right text-gray-600">{loan_data?.waived}</p>
</div>
<div className="d-flex align-items-center justify-content-between border border-gray-300 bg-gray-50 p-2 rounded shadow-sm">
<p className="fw-semibold text-gray-500">Total Amount to be generated:</p>
<p className="text-right text-gray-600">{loan_data?.waived_total_amount}</p>
</div>

                    <div className="d-flex align-items-center justify-content-center gap-3 mt-24">
                        <button
                            type="reset"
                            className="border border-danger-600 bg-hover-danger-200 text-danger-600 text-md px-40 py-11 radius-8"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="btn btn-primary border border-primary-600 text-md px-48 py-12 radius-8"
                            onClick={()=> handlecashfreelink(loan_data?.waived_total_amount, loan_data.loan_id)}
                        >
                            Generate
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
</div>
    </>  
    
)
}

export default Cashfreelink