import React, { useEffect, useRef, useState } from 'react'
import $ from 'jquery';
import 'datatables.net-dt/js/dataTables.dataTables.js';
import { Icon } from '@iconify/react/dist/iconify.js';
import { Link } from 'react-router-dom';
import InputDialog from './InputDialog';
import { useSelector } from 'react-redux';
import config from '../../config';
import { Loader } from './Loader';
import { DateRangePicker } from 'react-date-range';
import { addDays } from "date-fns";

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

const Form1 = () => {
    const [data, setData] = useState()
    const[loan, setLoan] = useState('undefined')
    const [loan_data, setloanData] = useState()
    const [selection, setSelection] = useState()
    const [totalPages , setTotalPages] = useState()
    const [currentPage, setCurrentPage] = useState(1)
    const [loading, setLoading] = useState(true); // Loading state
        const [openDate, setOpenDate] = useState(false)
    
        const [selectedOption, setSelectedOption] = useState('');
    
     // Loading state
        const [showCustomRange, setShowCustomRange] = useState(false);
        const [startDate, setStartDate] = useState('');
        const [start,setstart] = useState()
        const [end,setend] = useState()
        const [selectedDates, setSelectedDates] = useState([]);
        const [endDate, setEndDate] = useState('');
        const [formData, setFormData] = useState({});
        const [selectedOptions, setSelectedOptions] = useState('')
        const [agency, setAgency] = useState()
    
        const [from, setFrom] = useState('');
        const [to, setTo] = useState('');
        const [showCalendar, setShowCalendar] = useState(false); // State to toggle calendar visibility
      
        const [AgencySelection, setAgencySelection] = useState('')
    
        const [isSelecting, setIsSelecting] = useState(false);
          const [selectionRange, setSelectionRange] = useState({
            startDate: '',
            endDate: '',
            key: "selection",
          });

    const individual = useSelector((state)=>  state?.Individual?.individual_role)

    const serviceSelect = useRef(null);
    const dailySelect = useRef(null);

    const handleSelectChanges = (event) => {
        const value = event.target.value;
        setSelectedOptions(value);
        if (value === 'Loan Date' || value === 'Due Date') {
          setOpenDate(false);
        } else {
          setOpenDate(true);
        }
      };
      const toggleCalendar = () => {
        setShowCalendar(!showCalendar); // Toggle the calendar visibility
      };
    
      const handleApply = () => {
        setShowCalendar(false); // Close the calendar after applying the date range
      };

const handlechange = (e)=>{
    setSelection(e.target.value)

}
const PAGE_LIMIT = 5; // Number of pages to show before and after current page

const handlePageClick = (page) => {
    if (page < 1 || page > totalPages) return;
    setCurrentPage(page)
    fetchData();
};

const getPageNumbers = () => {
    const pages = [];
    const leftLimit = Math.max(1, currentPage - Math.floor(PAGE_LIMIT / 2));
    const rightLimit = Math.min(totalPages, currentPage + Math.floor(PAGE_LIMIT / 2));

    if (leftLimit > 1) {
        pages.push(1);
        if (leftLimit > 2) pages.push('...'); // Ellipsis for skipped pages
    }

    for (let i = leftLimit; i <= rightLimit; i++) {
        pages.push(i);
    }

    if (rightLimit < totalPages) {
        if (rightLimit < totalPages - 1) pages.push('...'); 
        pages.push(totalPages);
    }

    return pages;
};
    async function fetchData(){
      const token = localStorage.getItem('access_token')
        setLoading(true)

  
        const Respones = await fetch(`${config.apiUrl}/beta/api/collection/preadmin?${selectedOptions}=${formData.LoanId}&account_type=${formData.accountType}&${selectedOptions}start=${new Date(selectionRange.startDate).toLocaleDateString()}&${selectedOptions}end=${new Date(selectionRange.endDate).toLocaleDateString()}&${selectedOptions}=${AgencySelection}&slab=${selectedOption}&startslab=${start}&endslab=${end}&loanMin=${formData.loanMin}&loanMax=${formData.loanMax}&Pages=${currentPage}`,{
          method: 'GET',  // or 'POST' depending on the request type
          headers: {
            'Authorization': token,  // Replace with your actual token
            'Content-Type': 'application/json'  // Include this if you're sending JSON data
          }
        })
        if(loan != 'undefined' && loan.length > 0){
            const loan_data = await fetch(`${config.apiUrl}/beta/api/collection/get_waiver/${loan}?percInterest=${Number(dailySelect.current.value)}&serperInterest=${Number(serviceSelect.current.value)}`,{
              method: 'GET',
              headers:{
                'Authorization': token,  // Replace with your actual token
                'Content-Type': 'application/json'  // Include this if you're sending JSON data
              }
            })
            const json_data = await loan_data.json()
            setloanData(json_data.data)
    
    
            }
        const json_data = await Respones.json()
        if(Respones.ok){
            setLoading(false)
        }
        setTotalPages(json_data.totalPages)
        setData(json_data.data)
        
    }


    
    useEffect(()=>{
        if (loan) {
            fetchData();  // Only fetch when `loan` is defined

        }
        },[selection,currentPage,totalPages,showCustomRange,selectedOptions,startDate,endDate,selectedOption,AgencySelection,selectionRange])
    const handlecashfree = (event) =>{
        fetchData()
        const serviceValue = serviceSelect.current.value;
        const dailyValue = dailySelect.current.value;

    }
    const handleFile = async (e)=>{
      const token = localStorage.getItem('access_token')
        let Respones = await fetch(`${config.apiUrl}/beta/api/collection/preadmin?slab=${selection}&excel=true`,{
          headers:{
            'Authorization': token,  // Replace with your actual token
            'Content-Type': 'application/json'  // Include this if you're sending JSON data
          }
        })
        const json_data = await Respones.json()

        const binaryString = window.atob(json_data.body);  
        const byteArray = new Uint8Array(binaryString.length); 
        for (let i = 0; i < binaryString.length; i++) {
          byteArray[i] = binaryString.charCodeAt(i);
        }
    
        const blob = new Blob([byteArray], { type: 'application/octet-stream' }); 
        const url = window.URL.createObjectURL(blob);
    
        const link = document.createElement('a');
        link.href = url;
        link.download = 'loan_mapping.xlsx'; 
        link.click();
    
        window.URL.revokeObjectURL(url);



    }
    const handleClick = async(e)=>{
        let value = e.target.value
        setSelection(e.target.value)
        setSelectedOption(e.target.value)
        if (value === 'custom') {
            setShowCustomRange(true); // Show custom range input fields
          } else {
            setShowCustomRange(false); // Hide custom range input fields
          }
      }
      const handleCloseModal = () => {
        setstart('')
        setend('')
        setShowCustomRange(false);
      };
    const handleChanges = (e) => {
        setFormData({ ...formData, [e.target.id]: e.target.value.trim() });
      };
    const handlAgencySelectChange = (event)=>{
        setAgencySelection(event.target.value)
        fetchData()
    
      }
    const handleSelects= (ranges) => {
        setSelectionRange(ranges.selection); // Update the selectionRange state with new date range
    
      };
   const  handlecashfreelink = async(loan_amount, loan_id,service_charge,daily_late)=>{
    const token = localStorage.getItem('access_token')
    let postData = {
        loan_id: loan_id,
        link_amount: loan_amount,
        service_charge: service_charge,
        daily_late: daily_late
    }
    const response = await fetch(`${config.apiUrl}/beta/api/collection/cashfree_link`, {
        method: 'POST',               // Set the request method to POST
        headers: {
            'Content-Type': 'application/json', // Specify the content type
            'Authorization': token,  // Replace with your actual token

        },
        body: JSON.stringify(postData) // Convert the data to a JSON string
    });
    }


  return (
    <> 
     {     loading ? (
        <Loader loading={loading}/>
      ) : (
        <>
    
    <div className="card-header d-flex flex-wrap align-items-center justify-content-between gap-3">
  {/* <div className="d-flex flex-wrap gap-4">
    <div className="mb-3">
    </div>

    <div className="mb-3">
      <label className="form-label" htmlFor="LoanId">
        Select search
      </label>
      <select
        className="form-select w-auto"
        id="grid-state"
        value={selectedOptions}
        onChange={handleSelectChanges}
      >
        <option value="" disabled>---Select----</option>
        <option value="LoanDate">Loan Date</option>
        <option value="DueDate">Due Date</option>
        <option value="State">State</option>
        <option value="Agency">Agency</option>
        <option value="Mobile_Number">Mobile</option>
        <option value="Loan_Id">Loan Id</option>
      </select>
    </div>

    <div className="mb-3">
      <label className="form-label" htmlFor="LoanId">
        {selectedOptions || "Enter here"}
      </label>

      {selectedOptions === 'LoanDate' || selectedOptions === 'DueDate' ? (
        <>
          {openDate && (
            <div style={{ position: "relative", width: "300px" }}>
              <button
                onClick={toggleCalendar}
                style={{
                  padding: "7px 6px",
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                  width: "70%",
                  textAlign: "left",
                  backgroundColor: "#fff",
                  cursor: "pointer",
                }}
              >
                {selectionRange.startDate && selectionRange.endDate
                  ? `${selectionRange.startDate.toLocaleDateString()} - ${selectionRange.endDate.toLocaleDateString()}`
                  : "Select Date Range"
                }
              </button>
              {showCalendar && (
                <div
                  style={{
                    position: "absolute",
                    top: "50px",
                    left: "0",
                    zIndex: 999,
                    backgroundColor: "#fff",
                    padding: "10px",
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                  }}
                >
                  <DateRangePicker
                    ranges={[selectionRange]}
                    onChange={handleSelects}
                    showDateDisplay={false}
                  />
                  <div style={{ marginTop: "10px", textAlign: "center" }}>
                    <button
                      onClick={handleApply}
                      style={{
                        padding: "5px 7px",
                        border: "1px solid #007BFF",
                        borderRadius: "5px",
                        backgroundColor: "#007BFF",
                        color: "#fff",
                        cursor: "pointer",
                      }}
                    >
                      Apply
                    </button>
                  </div>
                </div>
              )}
            </div>
          )}
        </>
      ) : selectedOptions === 'Agency' ? (
        <select
          className="form-select w-auto"
          id="AgencyId"
          onChange={handlAgencySelectChange}
          value={AgencySelection}
        >
          <option value="" disabled>Select Agency</option>
          {agency?.map((data, index) => (
            <option key={index} value={data._id}>{data.username}</option>
          ))}
        </select>
      ) : selectedOptions === 'State' ? (
        <select
          className="form-select w-auto"
          id="AgencyId"
          onChange={handlAgencySelectChange}
          value={AgencySelection}
        >
          <option value="" disabled>Select State</option>
          {["Andhra Pradesh", "Arunachal Pradesh", "Assam", "Bihar", "Chhattisgarh", "Goa", "Gujarat", "Haryana", "Himachal Pradesh", "Jharkhand", "Karnataka", "Kerala", "Maharashtra", "Madhya Pradesh", "Manipur", "Meghalaya", "Mizoram", "Nagaland", "Odisha", "Punjab", "Rajasthan", "Sikkim", "Tamil Nadu", "Tripura", "Telangana", "Uttar Pradesh", "Uttarakhand", "West Bengal"].map((state) => (
            <option key={state} value={state}>{state}</option>
          ))}
        </select>
      ) : (
        <input
          className="form-control w-auto"
          id="LoanId"
          type="text"
          placeholder="Search here"
          onChange={handleChanges}
        />
      )}
    </div>

    <div className="d-flex flex-wrap gap-2">
      <button
        onClick={fetchData}
        className="btn btn-primary"
        style={{ height: '50px', width: '120px', marginTop: '30px' }}
      >
        Search
      </button>
    </div>
  </div> */}

  <div className="d-flex flex-wrap align-items-center gap-3">
    <div className="mb-3">
      <label className="form-label" htmlFor="LoanId">
        No. of days slab
      </label>
      <select
        id="status"
        className="form-select form-select-sm w-auto"
        defaultValue="Select Defaulted Loans"
        onChange={handleClick}
        style={{ maxWidth: '200px' }}
      >
        <option value="Select Defaulted Loans" disabled>Select pre slab</option>
        {[...Array(7)].map((_, i) => (
          <option key={i} value={i + 1}>{i + 1} days</option>
        ))}
        <option value="All">All</option>
      </select>

      {showCustomRange && (
        <div className="modal show" tabIndex="-1" style={{ display: 'block' }} aria-labelledby="customRangeModalLabel">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="customRangeModalLabel">Select Custom Slab Range</h5>
                <button type="button" className="btn-close" onClick={handleCloseModal}></button>
              </div>
              <div className="modal-body">
                <div className="d-flex flex-column gap-2">
                  <div className="mb-1">
                    <label htmlFor="start-date" className="form-label form-label-sm">Start Date</label>
                    <input
                      type="number"
                      id="start-date"
                      value={start}
                      onChange={(e) => setstart(e.target.value)}
                      className="form-control form-control-sm"
                      style={{ width: '45%' }}
                      placeholder="Start"
                    />
                  </div>
                  <div className="mb-1">
                    <label htmlFor="end-date" className="form-label form-label-sm">End Date</label>
                    <input
                      type="number"
                      id="end-date"
                      value={end}
                      onChange={(e) => setend(e.target.value)}
                      className="form-control form-control-sm"
                      style={{ width: '45%' }}
                      placeholder="End"
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={handleCloseModal}>Close</button>
                <button type="button" className="btn btn-primary" onClick={() => { /* Save custom range logic */ handleCloseModal(); }}>
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>

    {/* <div className="mb-3">
      <label className="form-label" htmlFor="LoanId">
        Min Loan Amount
      </label>
      <input
        className="form-control w-auto"
        id="loanMin"
        type="text"
        placeholder="Min Loan Amount"
        onChange={handleChanges}
      />
    </div>

    <div className="mb-3">
      <label className="form-label" htmlFor="LoanId">
        Max Loan Amount
      </label>
      <input
        className="form-control w-auto"
        id="loanMax"
        type="text"
        placeholder="Max Loan Amount"
        onChange={handleChanges}
      />
    </div>

    <div className="d-flex mt-7 gap-4">
      <button
        onClick={fetchData}
        className="btn btn-primary"
      >
        Search
      </button>
    </div> */}
  </div>

  <div className="d-flex flex-wrap align-items-center gap-3">
    {individual?.loan_details?.export_access === true && (
      <button className="btn btn-sm btn-primary-600" onClick={handleFile}>
        <i className="ri-add-line" /> Download XLSX
      </button>
    )}
  </div>
</div>


<div className="card-body">
<div style={{ overflowX: 'auto', maxHeight: '750px', overflowY: 'auto' }}>
<table className="table bordered-table mb-0">
    <thead>
        <tr>

            <th scope="col">Loan Id</th>
            <th scope="col">Loan Amount</th>
            <th scope="col">DPD Days</th>
            <th scope="col">Service charge</th>
            <th scope="col">Daily Late Fee</th>
            <th scope="col">Total Penalty charges</th>
            <th scope="col">Total Payable Amount</th>
            <th scope="col">start Date</th>
            <th scope="col">Due Date</th>

            <th scope="col">Name</th>
            <th scope="col">Mobile Number</th>
            <th scope="col">Generate Link</th>


            <th scope="col">Action</th>
        </tr>
    </thead>
    <tbody>
    {data?.map((data,index)=>(

<tr key={index}>

<td>
<Link to="#" className="text-primary-600">
{data.loan_id}
</Link>
</td>
<td>
<div className="d-flex align-items-center">

<h6 className="text-md mb-0 fw-medium flex-grow-1">
    {data.loan_amt}
</h6>
</div>
</td>
<td>{data.no_of_days_past_due}</td>
<td>{data.serviceChargeFee}</td>
<td>{data.dailyLateFee}</td>
<td>{data.totalPenalty}</td>
<td>{data.totalAmountPayable}</td>
<td>{(new Date(data.start).toISOString().split('T')[0])}</td>
<td>{(new Date(data.due).toISOString().split('T')[0])}</td>
<td>{data.student.name}</td>


<td>
{" "}
<span className="bg-success-focus text-success-main px-24 py-4 rounded-pill fw-medium text-sm">
{data.student.mobile}
</span>
</td>
<td>
{" "}
<span className="bg-red-focus text-red px-24 py-4 rounded-pill fw-medium text-sm">
<Icon              data-bs-toggle="modal"
        data-bs-target="#exampleModal"
icon="cil:paper-plane" style={{ fontSize: '24px', cursor: 'pointer' }} onClick={() => {
setLoan(String(data.loan_id))
}} />
</span>
</td>
<td>
<Link
to="#"
className="w-32-px h-32-px  me-8 bg-primary-light text-primary-600 rounded-circle d-inline-flex align-items-center justify-content-center"
>
<Icon icon="iconamoon:eye-light" />
</Link>


</td>
</tr>
    ))}

      
    </tbody>
</table>

</div>
<div className="d-flex flex-wrap align-items-center justify-content-between gap-2 mt-24">
            <span>
                Showing {(currentPage - 1) * 10 + 1} to {Math.min(currentPage * 10, totalPages * 10)} of {totalPages * 10} entries
            </span>
            <ul className="pagination d-flex flex-wrap align-items-center gap-2 justify-content-center">
                {/* Previous Button */}
                <li className="page-item">
                    <Link
                        className={`page-link text-secondary-light fw-medium radius-4 border-0 px-10 py-10 d-flex align-items-center justify-content-center h-32-px me-8 w-32-px ${currentPage === 1 ? 'bg-base' : ''}`}
                        to="#"
                        onClick={() => handlePageClick(currentPage - 1)}
                        disabled={currentPage === 1}
                    >
                        <Icon icon="ep:d-arrow-left" className="text-xl" />
                    </Link>
                </li>

                {/* Page Numbers */}
                {getPageNumbers().map((page, index) => (
                    <li key={index} className="page-item">
                        {/* Render page number or ellipsis */}
                        <Link
                            className={`page-link fw-medium radius-4 border-0 px-10 py-10 d-flex align-items-center justify-content-center h-32-px me-8 w-32-px ${
                                page === currentPage ? 'bg-primary-600 text-white' : 'bg-primary-50 text-secondary-light'
                            }`}
                            to="#"
                            onClick={() => page !== '...' && handlePageClick(page)}
                        >
                            {page}
                        </Link>
                    </li>
                ))}

                {/* Next Button */}
                <li className="page-item">
                    <Link
                        className={`page-link text-secondary-light fw-medium radius-4 border-0 px-10 py-10 d-flex align-items-center justify-content-center h-32-px me-8 w-32-px ${currentPage === totalPages ? 'bg-base' : ''}`}
                        to="#"
                        onClick={() => handlePageClick(currentPage + 1)}
                        disabled={currentPage === totalPages}
                    >
                        <Icon icon="ep:d-arrow-right" className="text-xl" />
                    </Link>
                </li>
            </ul>
        </div>

</div>
</>
)}
<div
className="modal fade"
id="exampleModal"
tabIndex={-1}
aria-labelledby="exampleModalLabel"
aria-hidden="true"
>
<div className="modal-dialog modal-lg modal-dialog modal-dialog-centered">
    <div className="modal-content radius-16 bg-base">
        <div className="modal-header py-16 px-24 border border-top-0 border-start-0 border-end-0">
            <h1 className="modal-title fs-5" id="exampleModalLabel">
                Generate Link
            </h1>
            <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
            />
        </div>
        <div className="modal-body p-24">
            <form action="#">
            <div className="d-flex flex-wrap align-items-center gap-3 mb-5">
            <p className="fw-semibold text-gray-500">Service  %:</p>

            <select id='service' className="form-select form-select-sm w-auto" defaultValue="service"  
                ref={serviceSelect}  // Reference to the DOM element
                onChange={handlecashfree} 
                              >
        <option value="service" disabled>
            Select Service charge discount
        </option>
        <option value="0">0%</option>
        <option value="25">25%</option>
        <option value="50">50%</option>
        <option value="75">75%</option>
        <option value="100">100%</option>

    </select>
    <p className="fw-semibold text-gray-500">Daily  %:</p>

    <select id='daily' className="form-select form-select-sm w-auto" defaultValue="daily" ref={dailySelect}   onChange={handlecashfree}  >
        <option value="daily" disabled>
            Select Defaulted Loans
        </option>
        <option value="0">0%</option>

        <option value="25">25%</option>
        <option value="50">50%</option>
        <option value="75">75%</option>
        <option value="100">100%</option>
    </select>
    </div>
                <div className="row">
                <div className="d-flex align-items-center justify-content-between border border-gray-300 bg-gray-50 p-2 rounded shadow-sm">
<p className="fw-semibold text-gray-500">Defaulting in days:</p>
<p className="text-right text-gray-600">{loan_data?.no_of_days_past_due}</p>
</div>
<div className="d-flex align-items-center justify-content-between border border-gray-300 bg-gray-50 p-2 rounded shadow-sm">
<p className="fw-semibold text-gray-500">Total Service Charge Fee:</p>
<p className="text-right text-gray-600">{loan_data?.serviceChargeFee}</p>
</div>
<div className="d-flex align-items-center justify-content-between border border-gray-300 bg-gray-50 p-2 rounded shadow-sm">
<p className="fw-semibold text-gray-500">Total Daily Charge Fee:</p>
<p className="text-right text-gray-600">{loan_data?.dailyLateFee}</p>
</div>
<div className="d-flex align-items-center justify-content-between border border-gray-300 bg-gray-50 p-2 rounded shadow-sm">
<p className="fw-semibold text-gray-500">Total Penalty:</p>
<p className="text-right text-gray-600">{loan_data?.totalPenalty}</p>
</div>
<div className="d-flex align-items-center justify-content-between border border-gray-300 bg-gray-50 p-2 rounded shadow-sm">
<p className="fw-semibold text-gray-500">Total Amount Payable:</p>
<p className="text-right text-gray-600">{loan_data?.totalAmountPayable}</p>
</div>
<div className="d-flex align-items-center justify-content-between border border-gray-300 bg-gray-50 p-2 rounded shadow-sm">
<p className="fw-semibold text-gray-500">Service Charge Payable:</p>
<p className="text-right text-gray-600">{loan_data?.service_waived}</p>
</div>
<div className="d-flex align-items-center justify-content-between border border-gray-300 bg-gray-50 p-2 rounded shadow-sm">
<p className="fw-semibold text-gray-500">Daily Charge Payable:</p>
<p className="text-right text-gray-600">{loan_data?.waived}</p>
</div>
<div className="d-flex align-items-center justify-content-between border border-gray-300 bg-gray-50 p-2 rounded shadow-sm">
<p className="fw-semibold text-gray-500">Total Amount to be generated:</p>
<p className="text-right text-gray-600">{loan_data?.waived_total_amount}</p>
</div>

                    <div className="d-flex align-items-center justify-content-center gap-3 mt-24">
                        <button
                            type="reset"
                            className="border border-danger-600 bg-hover-danger-200 text-danger-600 text-md px-40 py-11 radius-8"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="btn btn-primary border border-primary-600 text-md px-48 py-12 radius-8"
                            onClick={()=> handlecashfreelink(loan_data?.waived_total_amount, loan_data.loan_id,loan_data?.service_waived,loan_data?.waived)}
                        >
                            Generate
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
</div>
    </>
        
  )
}

export default Form1