import React, { useEffect, useState } from 'react'
import config from '../../config';
import FailedMessage from './FailedMessage';

const InputDialog = ({isOpen, onClose, onSubmit, loan}) => {
    const [input1, setInput1] = useState('');
    const [input2, setInput2] = useState('');
    const [data1, setData] = useState({})
    const [message, setMessage] = useState ()
    const [loading , setLoading] = useState()

    useEffect(() => {
      const dataFetch = async () => {
        const token = localStorage.getItem('access_token')
        
        try {
          function isBlank(input) {
            // Check if input is null, undefined, or only contains whitespace characters
            return !input || /^\s*$/.test(input) ? false : true;
          }
          if(isBlank(loan) == true){          
            const response = await fetch(`${config.apiUrl}/beta/api/collection/get_waiver/${loan}?percInterest=${input1}&serperInterest=${input2}`,{
              method: 'GET',
              headers: {
                'Authorization': token,  // Replace with your actual token
                'Content-Type': 'application/json'  // Include this if you're sending JSON data
              },
            });
          if (!response.ok) {
            setMessage(`HTTP error! status: ${response.status}`);
          }

        
          const jsonData = await response.json();
          setData(jsonData.data);
          if(response.ok){
            setMessage(jsonData.message)
          }
        }
        


        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
    
      dataFetch();
    }, [input1, input2,loan]);  // Add input1 and input2 as dependencies

   async function handleSubmit(){
    async function link(){
    let formData = {
      loan_id: loan,
      cashfree_type: "waiver",
      deductedAmountPayable: data1?.waived_total_amount,
      deducted_daily_late_fee: data1?.waived,
      service_charge_fee_deduction_log: data1?.service_waived,
      waivedAmount: Number(data1?.waived) +  Number(data1?.service_waived)
    }
    const token = localStorage.getItem('access_token')
      const res = await fetch(`${config.apiUrl}/beta/api/collection/cashfree_link`, {
        method: 'POST',
        headers: {
          'Authorization': token,  // Replace with your actual token
          'Content-Type': 'application/json'  // Include this if you're sending JSON data
        },        body: JSON.stringify(formData),
      }); 
    }
    link()

      onClose(); // Close the dialog after submission

    }
    const handleHideMessage = () =>     setMessage(''); // Clear the message when the alert hides

      if (!isOpen) return null
  return (
    <>

<div className="d-flex align-items-center justify-content-center bg-gray-500 bg-opacity-50 position-fixed inset-0">
  <div className="bg-white p-6 rounded-lg shadow-lg">
    <h2 className="text-lg font-bold mb-4"></h2>
    <input
      type="text"
      value={input1}
      onChange={(e) => setInput1(e.target.value)}
      placeholder="Enter the service charge discount %"
      className="w-100 mb-4 p-2 border border-gray-300 rounded"
    />
    <input
      type="text"
      value={input2}
      onChange={(e) => setInput2(e.target.value)}
      placeholder="Enter the daily fee discount %"
      className="w-100 mb-4 p-2 border border-gray-300 rounded"
    />
    <div className="mb-4 d-flex flex-column gap-2">
      <div className="d-flex align-items-center justify-content-between border border-gray-300 bg-gray-50 p-2 rounded shadow-sm">
        <p className="fw-semibold text-gray-500">Defaulting in days:</p>
        <p className="text-right text-gray-600">{data1?.no_of_days_past_due}</p>
      </div>
      <div className="d-flex align-items-center justify-content-between border border-gray-300 bg-gray-50 p-2 rounded shadow-sm">
        <p className="fw-semibold text-gray-500">Total Service Charge Fee:</p>
        <p className="text-right text-gray-600">{data1?.serviceChargeFee}</p>
      </div>
      <div className="d-flex align-items-center justify-content-between border border-gray-300 bg-gray-50 p-2 rounded shadow-sm">
        <p className="fw-semibold text-gray-500">Total Daily Charge Fee:</p>
        <p className="text-right text-gray-600">{data1?.dailyLateFee}</p>
      </div>
      <div className="d-flex align-items-center justify-content-between border border-gray-300 bg-gray-50 p-2 rounded shadow-sm">
        <p className="fw-semibold text-gray-500">Total Penalty:</p>
        <p className="text-right text-gray-600">{data1?.totalPenalty}</p>
      </div>
      <div className="d-flex align-items-center justify-content-between border border-gray-300 bg-gray-50 p-2 rounded shadow-sm">
        <p className="fw-semibold text-gray-500">Total Amount Payable:</p>
        <p className="text-right text-gray-600">{data1?.totalAmountPayable}</p>
      </div>
      <div className="d-flex align-items-center justify-content-between border border-gray-300 bg-gray-50 p-2 rounded shadow-sm">
        <p className="fw-semibold text-gray-500">Service Charge Payable:</p>
        <p className="text-right text-gray-600">{data1?.service_waived}</p>
      </div>
      <div className="d-flex align-items-center justify-content-between border border-gray-300 bg-gray-50 p-2 rounded shadow-sm">
        <p className="fw-semibold text-gray-500">Daily Charge Payable:</p>
        <p className="text-right text-gray-600">{data1?.waived}</p>
      </div>
      <div className="d-flex align-items-center justify-content-between border border-gray-300 bg-gray-50 p-2 rounded shadow-sm">
        <p className="fw-semibold text-gray-500">Total Amount to be generated:</p>
        <p className="text-right text-gray-600">{data1?.waived_total_amount}</p>
      </div>
    </div>
    <div className="d-flex justify-content-between">
      <button onClick={handleSubmit} className="bg-danger text-white px-4 py-2 rounded">
        Cancel
      </button>
      <button onClick={handleSubmit} className="bg-primary text-white px-4 py-2 rounded">
        Submit
      </button>
    </div>
  </div>
  {message && <FailedMessage message={message} onHide={handleHideMessage} />}

</div>
</>

   )
}

export default InputDialog