import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import 'datatables.net-dt/js/dataTables.dataTables.js';
import { Icon } from '@iconify/react/dist/iconify.js';
import { Form, Link } from 'react-router-dom';
import InputDialog from './InputDialog';
import Form1 from './Form1';
import Form2 from './Form2';
import Form3 from './Form3';
import Form4 from './Form4';
import config from '../../config';

const LoanMappings = () => {
  const [data, setData] = useState();
  const [loan, setLoan] = useState('');
  const [activeTab, setActiveTab] = useState(1);  // Default tab is 1

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  
  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  // Fetch data from API
  async function fetchData() {
    const token = localStorage.getItem('access_token')
    const response = await fetch(`${config.apiUrl}/beta/api/collection/admindefaulter`,{
      method: 'GET',
      headers: {
        'Authorization': token,  // Replace with your actual token
        'Content-Type': 'application/json'  // Include this if you're sending JSON data
      },
    });
    const json_data = await response.json();
    setData(json_data.data);
  }

  // Fetch data on component mount and check for active tab in localStorage
  useEffect(() => {
    fetchData();

    // Retrieve saved activeTab from localStorage if available
    const storedTab = localStorage.getItem('activeTab');
    if (storedTab) {
      setActiveTab(Number(storedTab)); // Set activeTab based on stored value
    }
  }, []);

  // Handle tab change and save to localStorage
  const handleTabChange = (tabIndex) => {
    setActiveTab(tabIndex);
    localStorage.setItem('activeTab', tabIndex);  // Store active tab in localStorage
  };

  return (
    <>
      <div className="card">
        <div className="card-header d-flex flex-wrap align-items-center justify-content-between gap-3">
          <div className="d-flex flex-wrap align-items-center gap-3">
            <div className="d-flex align-items-center gap-2">
              <div className="d-flex justify-content-between mb-3 gap-3">
                <button
                  className={`btn ${activeTab === 1 ? 'btn-primary' : 'btn-outline-primary'} rounded-3 px-18 py-100 gap-2`}
                  onClick={() => handleTabChange(1)}  // Handle tab change and store in localStorage
                >
                  Pre Due
                </button>
                <button
                  className={`btn ${activeTab === 2 ? 'btn-primary' : 'btn-outline-primary'} rounded-3 px-4 py-2 ml-2`}
                  onClick={() => handleTabChange(2)}  // Handle tab change and store in localStorage
                >
                  Defaulted
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Render different forms based on the active tab */}
        {activeTab === 1 && <Form3 />}
        {activeTab === 2 && <Form4 />}
      </div>
    </>
  );
};

export default LoanMappings;
