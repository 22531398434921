import React from 'react'
import StucredAgencies from '../masterLayout/StucredAgencies'
import Breadcrumb from '../components/Breadcrumb'
import AddAgentList from '../components/child/AddAgentList'

const AddAgent = () => {
  return (
    <StucredAgencies>

      {/* Breadcrumb */}
      <Breadcrumb title="Add Agent" />

      {/* AddUserLayer */}
      <AddAgentList />


    </StucredAgencies>  )
}

export default AddAgent