import React from 'react'
import StucredAgencies from '../masterLayout/StucredAgencies'
import Breadcrumb from '../components/Breadcrumb'
import UnitCountFour from '../components/child/UnitCountFour'
import UnitCountFour2 from '../components/child/UnitCountFour2'
import EarningStaticOne from '../components/child/EarningStaticOne'
import UserActivatesTwo from '../components/child/UserActivatesTwo'
import DoubleChartCollect1 from '../components/child/DoubleChartCollect1'
import UnitCountFourAgencies from '../components/child/UnitCountFourAgencies'

const InhouseAgencies = () => {
  return (
    <StucredAgencies  >
        <Breadcrumb title="Over All Dashboard" />

                    <div className="row gy-4">

                        <UnitCountFourAgencies />
                        <UnitCountFour2 />
                        <EarningStaticOne/>
                        <UserActivatesTwo/>
                        <DoubleChartCollect1/>
                        </div>

</StucredAgencies>  )
}

export default InhouseAgencies