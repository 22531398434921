import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Icon } from '@iconify/react/dist/iconify.js'
import { useDispatch, useSelector } from 'react-redux'
import { signInFailure, signInStart, signInSuccess } from '../redux/user/userSlice'
import { roleFailure, roleStart, roleSucess } from '../redux/role_access/role_access'
import { IndividualSuccess } from '../redux/individual_role/Individual_role'
import config from '../config'
import FailedMessage from '../components/child/FailedMessage'

const SignInLayer = () => {
    const [formData, setFormData] = useState({})
    const [confirmpassword, setconfirmPassword] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const user_data = useSelector((state)=> state?.user)
    const user_role = useSelector((state)=> state.roles)
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState()

console.log(user_data,"===========ddddddd===============sss")
    const dispatch = useDispatch()
    if(user_data?.currentUser?.role ==  "SuperAdmin" || user_data?.currentUser?.role == 'admin' ){
      const role = {
        OverAll_dashboard: user_role?.currentRole?.find(d => d.name === "OverAll_dashboard"),
        Agency_dashboard: user_role?.currentRole?.find(d => d.name === "Agency_dashboard"),
        Inhouse_dashboard: user_role?.currentRole?.find(d => d.name === "Inhouse_dashboard"),
        loan_mapping: user_role?.currentRole?.find(d => d.name === "loan_mapping"),
        user_list: user_role?.currentRole?.find(d => d.name === "user_list"),
        add_user: user_role?.currentRole?.find(d => d.name === "add_user"),
        view_profile: user_role?.currentRole?.find(d => d.name === "view_profile"),
        role_access: user_role?.currentRole?.find(d => d.name === "role_access"),
        assign_role: user_role.currentRole?.find(d => d.name === "assign_role"),
        loan_details: user_role?.currentRole?.find(d => d.name === "loan_details"),

    };

  
    // Dispatch the action to update the store
    dispatch(IndividualSuccess(role));
  
  }
  if(user_data?.currentUser?.role == "StucredAgencies" || user_data?.currentUser?.role == "Agencies"){
    const role = {
      Stucred_lead_dashboard: user_role?.currentRole?.find(d => d.name === "Stucred_lead_dashboard"),
      loan_details: user_role?.currentRole?.find(d => d.name === "loan_details"),
      loan_mapping: user_role.currentRole?.find(d => d.name === "loan_mapping"),
      agent_list: user_role?.currentRole?.find(d => d.name === "agent_list"),
      add_agent:  user_role?.currentRole?.find(d => d.name === "add_agent"),
      view_profile: user_role?.currentRole?.find(d => d.name === "view_profile")
    }
    dispatch(IndividualSuccess(role));

  }

  if(user_data?.currentUser?.role == "StucredAgent" || user_data?.currentUser?.role == "Agent"){
    const role = {
      inhouse_dashboard: user_role?.currentRole.find(d => d.name === "inhouse_dashboard"),
      loan_details: user_role?.currentRole.find(d => d.name === "loan_details"),
      loan_mapping: user_role?.currentRole.find(d => d.name === "loan_mapping"),
      view_profile: user_role?.currentRole.find(d => d.name === "view_profile")
    }
    dispatch(IndividualSuccess(role));

  }
    useEffect(()=>{
      const token = localStorage.getItem('access_token')

    },[showPassword,confirmpassword,formData])

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.id]: e.target.value.trim() });
      };
      const { loading, error: errorMessage } = useSelector((state) => state.user);
      const navigate = useNavigate();
    const handleSubmit = async (e) => {
      setIsLoading(true)
        e.preventDefault();
        if (!formData.email ) {

          return dispatch(signInFailure('Please fill all the fields'));
        }
        try {
          dispatch(signInStart());
          dispatch(roleStart())
          const res = await fetch(`${config.apiUrl}/beta/api/collection/signin`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(formData),
          });
                  const roles_name = await fetch(`${config.apiUrl}/beta/api/collection/roles?roleName=${user_data?.currentUser?.role}&user_id=${user_data?.currentUser?._id}`,{

                  })
          let roles_data = await roles_name.json()
          let data = await res.json();
          if(data.status == false){
            setMessage(data.message)
            setIsLoading(false)
          }
          if(res.ok){
            setIsLoading(false)
          }
       
          console.log(roles_data,"=========================kkkkkkk")
          if(roles_name.ok){
            dispatch(roleSucess(roles_data.result[0]?.access_info))
            setIsLoading(false)

          }
          console.log(roles_data,"============sss====sssssssss===========ssss")
          if(roles_data.success === false){
            dispatch(roleFailure(roles_data.message))
            setIsLoading(false)


          }
        
      
          let token = data.token
          data = data.rest
          // const token = await res.headers
          if(data.newUser == true){
            setconfirmPassword(true)
            setShowPassword(true)
          }else{
            setShowPassword(true)
          }
          if (data.status == false) {
            setMessage(data.message)
            setIsLoading(false)

            dispatch(signInFailure(data.message));
            setIsLoading(false)

            dispatch(signInSuccess())
          }
          if (res.ok) {
            if(data.newUser == true){
              navigate('/')
            }
            if(data.newUser == true || data.newUser == false){
    
              if(token !== undefined){
                localStorage.setItem('access_token',token)
              }
            dispatch(signInSuccess(data));
     
       
    
          }
     

            if(data.role === 'SuperAdmin' && data.newUser === false && showPassword == true && formData.password !== undefined ){
            navigate('/overall');

          }else if(data.role === 'admin' && data.newUser === false && showPassword == true && formData.password !== undefined){
            navigate('/overall');


          }else if(data.role === 'StucredAgencies' && data.newUser === false && formData.password !== undefined ){
            navigate('/stucred_agencies')
          }else if(data.role === 'Agencies' && data.newUser === false && formData.password !== undefined ){
            navigate('/stucred_agencies')
          }else if(data.role === "StucredAgent" && data.newUser === false && formData.password !== undefined || null){
            navigate("/stucred_dashboard")
          }else{
            navigate("/")
          }
          }
        } catch (error) {
          dispatch(signInFailure(error.message));
        }
      };
      const handleHideMessage = () =>     setMessage(''); // Clear the message when the alert hides


  return (
    <>
                                {message && <FailedMessage message={message} onHide={handleHideMessage} />}
                                <section className="auth bg-base d-flex flex-wrap">
    <div className="auth-left d-lg-block d-none">
        <div className="d-flex align-items-center flex-column h-100 justify-content-center">
            <img src="assets/images/login.jpg" alt="" />
        </div>
    </div>
    <div className="auth-right py-32 px-24 d-flex flex-column justify-content-center">
        <div className="max-w-464-px mx-auto w-100">
            <div>
                <Link to="/" className="mb-40 max-w-290-px">
                </Link>
                <h4 className="mb-12">Sign In to your Stucred Collection</h4>
                <p className="mb-32 text-secondary-light text-lg">
                    Welcome back! please enter your detail
                </p>
            </div>
            <form onSubmit={handleSubmit} >
                <div className="icon-field mb-16">
                    <span className="icon top-50 translate-middle-y">
                        <Icon icon="mage:email" />
                    </span>
                    <input
                        type="email"
                        className="form-control h-56-px bg-neutral-50 radius-12"
                        placeholder="Email"
                        name='email'
                        id='email'
                        onChange={handleChange}
                        
                    />
                    
                </div>
                <div className="icon-field mb-16">

                {showPassword && (
                                 <input
                                 type="password"
                                 className="form-control h-56-px bg-neutral-50 radius-12"
                                 placeholder="Password"
                                 name='password'
                                 id='password'
                                 onChange={handleChange}
                                 
                             />
                                    )}
                           </div>
                           <div className="icon-field mb-16">

{confirmpassword && (
                 <input
                 type="ConfirmPassword"
                 className="form-control h-56-px bg-neutral-50 radius-12"
                 placeholder="Confirm Password"
                 name='ConfirmPassword'
                 id='ConfirmPassword'
                 onChange={handleChange}
                 
             />
                    )}
           </div>

      
                <div className="">
                    <div className="d-flex justify-content-between gap-2">
                        <div className="form-check style-check d-flex align-items-center">
                            <input
                                className="form-check-input border border-neutral-300"
                                type="checkbox"
                                defaultValue=""
                                id="remeber"
                                onChange={handleChange}
                            />
                                 
                            <label className="form-check-label" htmlFor="remeber">
                                Remember me{" "}
                            </label>
                        </div>
                        <Link to="/forgot-password" className="text-primary-600 fw-medium">
                            Forgot Password?
                        </Link>
                    </div>
                </div>
                <button
            type="submit"
            className="btn btn-primary text-sm btn-sm px-12 py-16 w-100 radius-12 mt-32"
            // disabled={isLoading}
            onClick={handleSubmit}
        >
            {isLoading ? (
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            ) : (
                "Sign In"
            )}
        </button>
                <div className="mt-32 center-border-horizontal text-center">
                            <span className="bg-base z-1 px-4">Or sign in with</span>
                        </div>
                        <div className=''>
                        <div className="text-sm btn-sm px-12 py-16 w-100 radius-12 mt-32">
                            <Link to={'/stucred_login'}
                                type="button"
                                className="fw-semibold text-primary-light py-16 px-12 w-100 border radius-12 text-md d-flex align-items-center justify-content-center gap-12 line-height-1 bg-hover-primary-50"
                            >
       <img
                      src="assets/images/stpng.png"
                      alt="image_user"
                      className="w-40-px h-40-px object-fit-cover rounded-circle"
                    />                                Stucred
                            </Link>
                            </div>
                       
                        </div>
                
            </form>
        </div>
    </div>
</section>
</>
  )
}

export default SignInLayer