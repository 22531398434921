import { useSelector } from 'react-redux';
import { Outlet, Navigate } from 'react-router-dom';

export default function PublicRoute() {
  const currentUser = useSelector((state) => state?.user?.currentUser);

  if (currentUser?.role == 'admin' || currentUser?.role == 'SuperAdmin') {
    return <Navigate to="/overall" />;
  }
  if(currentUser?.role == 'Agencies' || currentUser?.role == 'StucredAgencies'){
    return <Navigate to='/stucred_agencies'/>
  }
  if(currentUser?.role == 'StucredAgent'){
    return <Navigate to='/stucred_dashboard'/>
  }

  // If not logged in, allow them to access the login page (the / route)
  return <Outlet />;
}