import React, { useState, useEffect } from 'react';
import Icon from 'react-multi-date-picker/components/icon';
import { Link } from 'react-router-dom';

const ModalLoan = ({ show, handleClose, CogID }) => {
  const [remarks, setRemarks] = useState('');
  const [remarksStatus, setRemarksStatus] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [data, setData] = useState([]);

  const handleRemarksChange = (e) => setRemarks(e.target.value);
  const handleRemarksStatusChange = (e) => setRemarksStatus(e.target.checked);

  useEffect(() => {
    if (show && CogID) {
      fetchData();  // Fetch data when modal is shown and loanId is available
    }
  }, [show, CogID]);

  async function fetchData() {
    const token = localStorage.getItem('access_token');
    const response = await fetch(`http://localhost:3000/beta/api/collection/loans/${CogID}?pages=${currentPage}`, {
      method: 'GET',
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json'
      }
    });
    const json_data = await response.json();
    console.log(json_data,"==========================json_data")
    setData(json_data.data);  // Assuming loans is the array in the response
    setTotalPages(json_data.totalPages);  // Set total pages based on response
  }

  const handlePageClick = (page) => {
    if (page < 1 || page > totalPages) return;
    setCurrentPage(page);
    fetchData();
  };

  const PAGE_LIMIT = 10;

  const getPageNumbers = () => {
    const pages = [];
    const leftLimit = Math.max(1, currentPage - Math.floor(PAGE_LIMIT / 2));
    const rightLimit = Math.min(totalPages, currentPage + Math.floor(PAGE_LIMIT / 2));

    if (leftLimit > 1) {
      pages.push(1);
      if (leftLimit > 2) pages.push('...');
    }

    for (let i = leftLimit; i <= rightLimit; i++) {
      pages.push(i);
    }

    if (rightLimit < totalPages) {
      if (rightLimit < totalPages - 1) pages.push('...');
      pages.push(totalPages);
    }

    return pages;
  };

  return (
    <>
      {show && (
        <>
          <div
            className="modal fade show"
            id="exampleModal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            style={{ display: 'block' }} // Modal is shown when 'show' is true
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Edit Remarks for Loan ID: {CogID}</h5>
                  <button type="button" className="btn-close" onClick={handleClose}></button>
                </div>
                <div className="modal-body">
                  <div className="card-body">
                    <div style={{ overflowX: 'auto', maxHeight: '750px', overflowY: 'auto' }}>
                      <table className="table bordered-table mb-0">
                        <thead>
                          <tr>
                      
                            <th scope="col">Loan Id</th>
                            <th scope="col">Loan Amount</th>
                            <th scope="col">DPD Days</th>
                            <th scope="col">Start Date</th>
                            <th scope="col">Due Date</th>
                            <th scope="col">Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">mobile Number</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data?.map((loan, index) => (
                            <tr key={loan.loan_id || index}>
                            
                              <td>
                                <Link to="#" className="text-primary-600">
                                  {loan.loan_id}
                                </Link>
                              </td>
                              <td>
                                <div className="d-flex align-items-center">
                                  <h6 className="text-md mb-0 fw-medium flex-grow-1">
                                    {loan.loan_amt}
                                  </h6>
                                </div>
                              </td>
                              <td>{loan.defaulted}</td>
                              <td>{loan.start && !isNaN(new Date(loan.start)) ? new Date(loan.start).toISOString().split('T')[0] : 'N/A'}</td>
                              <td>{loan.due && !isNaN(new Date(loan.due)) ? new Date(loan.due).toISOString().split('T')[0] : 'N/A'}</td>
                              <td>{loan?.student?.name}</td>
                              <td>{loan?.student?.email}</td>
                              <td>
                                <span className="bg-success-focus text-success-main px-24 py-4 rounded-pill fw-medium text-sm">
                                  {loan?.student?.mobile}
                                </span>
                              </td>
                          
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="d-flex flex-wrap align-items-center justify-content-between gap-2 mt-24">
                      <span>
                        Showing {(currentPage - 1) * 10 + 1} to {Math.min(currentPage * 10, totalPages * 10)} of {totalPages * 10} entries
                      </span>
                      <ul className="pagination d-flex flex-wrap align-items-center gap-2 justify-content-center">
                        <li className="page-item">
                          <Link
                            className={`page-link text-secondary-light fw-medium radius-4 border-0 px-10 py-10 d-flex align-items-center justify-content-center h-32-px me-8 w-32-px ${currentPage === 1 ? 'bg-base' : ''}`}
                            to="#"
                            onClick={(e) => { e.preventDefault(); handlePageClick(currentPage - 1); }}
                          >
                            <Icon icon="ep:d-arrow-left" className="text-xl" />
                          </Link>
                        </li>
                        {getPageNumbers().map((page, index) => (
                          <li key={`${page}-${index}`} className="page-item">
                            <Link
                              className={`page-link fw-medium radius-4 border-0 px-10 py-10 d-flex align-items-center justify-content-center h-32-px me-8 w-32-px ${
                                page === currentPage ? 'bg-primary-600 text-white' : 'bg-primary-50 text-primary-600'
                              }`}
                              to="#"
                              onClick={(e) => { e.preventDefault(); if (page !== '...') handlePageClick(page); }}
                              disabled={page === '...'}
                            >
                              {page}
                            </Link>
                          </li>
                        ))}
                        <li className="page-item">
                          <Link
                            className={`page-link text-secondary-light fw-medium radius-4 border-0 px-10 py-10 d-flex align-items-center justify-content-center h-32-px me-8 w-32-px ${currentPage === totalPages ? 'bg-base' : ''}`}
                            to="#"
                            onClick={(e) => { e.preventDefault(); handlePageClick(currentPage + 1); }}
                          >
                            <Icon icon="ep:d-arrow-right" className="text-xl" />
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Backdrop */}
          <div className="modal-backdrop fade show"></div>
        </>
      )}
    </>
  );
};

export default ModalLoan;
