import React, { useEffect, useState } from 'react'
import $ from 'jquery';
import 'datatables.net-dt/js/dataTables.dataTables.js';
import { Icon } from '@iconify/react/dist/iconify.js';
import { Link } from 'react-router-dom';
import InputDialog from './InputDialog';
import { Button } from 'bootstrap';
import { useSelector } from 'react-redux';
import config from '../../config';
import { Loader } from './Loader';
import * as XLSX from 'xlsx';  // Library for handling Excel file parsing
import AWS from 'aws-sdk';


const Form3 = () => {
    const [data, setData] = useState()
    const[loan, setLoan] = useState('')
    const [activeTab, setActiveTab] = useState(1);
    const [agency, setAgency] = useState()
    const [file, setFile] = useState(null); // Store the selected file in state
    const [totalData, setTotal] = useState()
    const [totalPages , setTotalPages] = useState()
    const [currentPage, setCurrentPage] = useState(1)
    const [selection, setSelection] = useState()
    const [total_count, setTotalCount] = useState()
    const [loading, setLoading] = useState(true); // Loading state

    const [ID , setID] = useState()

    const individual = useSelector((state)=>  state.Individual.individual_role)

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const handleOpenDialog = () => {
        setIsDialogOpen(true);
      };
      const handleCloseDialog = () => {
        setIsDialogOpen(false);
      };
      const PAGE_LIMIT = 5; // Number of pages to show before and after current page

      const handlePageClick = (page) => {
        if (page < 1 || page > totalPages) return;
        setCurrentPage(page)
        fetchData();
    };
    
    const getPageNumbers = () => {
        const pages = [];
        const leftLimit = Math.max(1, currentPage - Math.floor(PAGE_LIMIT / 2));
        const rightLimit = Math.min(totalPages, currentPage + Math.floor(PAGE_LIMIT / 2));
    
        if (leftLimit > 1) {
            pages.push(1);
            if (leftLimit > 2) pages.push('...'); // Ellipsis for skipped pages
        }
    
        for (let i = leftLimit; i <= rightLimit; i++) {
            pages.push(i);
        }
    
        if (rightLimit < totalPages) {
            if (rightLimit < totalPages - 1) pages.push('...'); 
            pages.push(totalPages);
        }
    
        return pages;
    };
     const convertExcelToJson = (file) => {
            return new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.onload = (e) => {
                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, { type: 'array' });
          
                // Assuming the first sheet is what you need
                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];
          
                // Convert sheet to JSON
                const jsonData = XLSX.utils.sheet_to_json(sheet);
                resolve(jsonData);
              };
              reader.onerror = (error) => reject(error);
          
              reader.readAsArrayBuffer(file);
            });
          };
          AWS.config.update({
            accessKeyId: 'AKIAVSP6G66YE2CUPRVO',         // Replace with your AWS access key ID
            secretAccessKey: 'U+Do303kCDEVZD+YajvuZHkfI4b54vCqL1ctVT+k', // Replace with your AWS secret access key
            region: 'ap-south-1',  
          });
          const s3 = new AWS.S3({
            accessKeyId: 'AKIAVSP6G66YE2CUPRVO',         // Replace with your AWS access key ID
            secretAccessKey: 'U+Do303kCDEVZD+YajvuZHkfI4b54vCqL1ctVT+k', // Replace with your AWS secret access key
            region: 'ap-south-1',  
          });
    
          const uploadJsonToS3 = async (jsonData, fileName) => {
            try {
              // Create a Blob from the JSON data
              const blob = new Blob([JSON.stringify(jsonData)], { type: 'application/json' });
          
              const params = {
                Bucket: 'stucredcollections-uploads',  // Replace with your actual bucket name
                Key: 'loan_details',  // Save file as JSON
                Body: blob,
                ContentType: 'application/json',  // Set the content type to JSON
              };
          
              // Upload to S3
              const uploadResult = await s3.upload(params).promise();
          
              console.log('File uploaded successfully at:', uploadResult.Location);
              
              // Return the file URL from S3
              return uploadResult.Location;
            } catch (error) {
              console.error('Error uploading JSON to S3:', error);
              throw error;
            }
          };
          
      
      const sendUrlToBackend = async (fileUrl) => {
        const token = localStorage.getItem('access_token');
        const response = await fetch(`${config.apiUrl}/beta/api/collection/preUpload`, {
          method: 'POST',
          headers: {
            'Authorization': token,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            fileUrl,  // Send the S3 file URL to the backend
          }),
        });
      
        if (!response.ok) {
          console.error('Failed to send file URL to backend');
        } else {
          const result = await response.json();
          console.log('Backend response:', result);
        }
      };
      
    
      const handleFileChange = async (event) => {
        const selectedFile = event.target.files[0];
        
        // Set the file to state
        setFile(selectedFile);
        const jsonData = await convertExcelToJson(selectedFile)
        const jsonUrl = await uploadJsonToS3(jsonData, selectedFile.name)
        console.log(jsonUrl,"============================")
    
        // Create a new FormData object
        const formData = new FormData();
        formData.append('file', selectedFile); // Append the selected file directly
    const token = localStorage.getItem('access_token')
        // Send the request to the server
        const response = await fetch(`${config.apiUrl}/beta/api/collection/preUpload`, {
            method: 'POST',
            body: jsonUrl, // Send the form data containing the file
            headers: {
              'Authorization': token,  // Replace with your actual token
            //   'Content-Type': 'application/json'  // Include this if you're sending JSON data
            },
          })
    
        if (response.ok) {
          const result = await response.json();
        } else {
          console.error('Failed to upload file');
        }
      };

    const handleSelection = async(e)=>{
        setSelection(e.target.value)
    }
    async function fetchData(){
        const token = localStorage.getItem('access_token')
        const Respones = await fetch(`${config.apiUrl}/beta/api/collection/preadmin?Pages=${currentPage}&slab=${selection}`,{
            method: 'GET',
            headers: {
              'Authorization': token,  // Replace with your actual token
              'Content-Type': 'application/json'  // Include this if you're sending JSON data
            },
        })

        const agency = await fetch(`${config.apiUrl}/beta/api/collection/getAllAgencies`,{
            method: 'GET',
            headers: {
              'Authorization': token,  // Replace with your actual token
              'Content-Type': 'application/json'  // Include this if you're sending JSON data
            },
        })

        const json_datas = await agency.json()
        setAgency(json_datas.data)
        const json_data = await Respones.json()
        if(Respones.ok){
            setLoading(false)
        }
        setData(json_data.data)
        setTotalPages(json_data.totalPages)
        
    }
    const handleAgency = async(e)=>{
        setID(e.target.value)

    }
   const handlemapped = async(e)=>{
    const token = localStorage.getItem('access_token')
    let response = await fetch(`${config.apiUrl}/beta/api/collection/updateprecollections?slab=${selection}`, {
        method: 'PUT',
        headers: {
            'Authorization': token,  // Replace with your actual token
            'Content-Type': 'application/json'  // Specify that you're sending JSON
        },
        body: JSON.stringify({ ID })  // Ensure the body is serialized as a JSON string
    });
   }
    const handleFile = async (e)=>{
        const token = localStorage.getItem('access_token')
        let Respones = await fetch(`${config.apiUrl}/beta/api/collection/preadmin?excel=true&Pages=${currentPage}&slab=${selection}`,{
            method: 'GET',
            headers: {
              'Authorization': token,  // Replace with your actual token
              'Content-Type': 'application/json'  // Include this if you're sending JSON data
            },
        })
        const json_data = await Respones.json()

        const binaryString = window.atob(json_data.body);  
        const byteArray = new Uint8Array(binaryString.length); 
        for (let i = 0; i < binaryString.length; i++) {
          byteArray[i] = binaryString.charCodeAt(i);
        }
    
        const blob = new Blob([byteArray], { type: 'application/octet-stream' }); 
        const url = window.URL.createObjectURL(blob);
    
        const link = document.createElement('a');
        link.href = url;
        link.download = 'PreMapping.xlsx'; 
        link.click();
    
        window.URL.revokeObjectURL(url);



    }
  const handletotal = async(e)=>{
    const token = localStorage.getItem('access_token')
    const total_data = await fetch(`http://localhost:3000/beta/api/collection/pre_loan?excel=true&slab=undefined`,{
        method: 'GET',
        headers: {
          'Authorization': token,  // Replace with your actual token
          'Content-Type': 'application/json'  // Include this if you're sending JSON data
        },
    })
    const total_json_data = await total_data.json()
    setTotalCount(total_json_data.total_document)


  }
    useEffect(()=>{
        fetchData()
    },[selection,currentPage,file])
  return (
    <> 
        { loading ? (
        <Loader loading={loading}/>

    ):(
        <>
       <div className="card-header d-flex flex-wrap align-items-center justify-content-between gap-3">
       {individual?.loan_mapping?.readwrite === true && (

<div className="d-flex flex-wrap align-items-center gap-3">
    <select id='status' className="form-select form-select-sm w-auto" defaultValue="Select Status" onChange={handleSelection}>
        <option value="Select Status" disabled>
            Select pre slab
        </option>
        <option value="1">1 days </option>
        <option value="2">2 days </option>
        <option value="3">3 days </option>
        <option value="4">4 days </option>
        <option value="5">5 days </option>
        <option value="6">6 days </option>
        <option value="7">7 days</option>
        <option value="All">All</option>


    </select>

    <select id='status' className="form-select form-select-sm w-auto" defaultValue="Agency" onChange={handleAgency}>
    <option value="Agency" disabled>
        Select Agency
    </option>

    {agency?.map((data,index)=>(
                <option key={index} value={data._id}>{data.username}</option>

    ))}
    </select>

    <button
  className="btn-primary rounded-2 px-6 py-8 ml-2"
  style={{ backgroundColor: '#007bff', color: 'white' }}
                          data-bs-toggle="modal"
                        data-bs-target="#Modal1"
                        onClick={handletotal}
>
          Auto Map
        </button>




</div>
        )}

<div className="d-flex flex-wrap align-items-center gap-3">


{individual?.loan_mapping?.export_access === true && (

<button className="btn btn-sm btn-primary-600" onClick={handleFile}>
        <i className="ri-add-line" /> Download XLSX
    </button>
)}
{individual?.loan_mapping?.readwrite === true && (    <label
                        htmlFor="file-upload-name"
                        className="mb-14 border border-neutral-600 fw-medium text-secondary-light px-16 py-10 radius-12 d-inline-flex align-items-center gap-1 bg-hover-neutral-200"
                    >
                        <Icon icon="solar:upload-linear" className="text-xl"></Icon>
                        upload xlsx map
                        <input
                            type="file"
                            className="form-control w-auto mt-24 form-control-lg"
                            id="file-upload-name"
                            multiple
                            hidden
                            onChange={handleFileChange}
                        />
                    </label>
                    )}

</div>
</div>

<div className="card-body">
<div style={{ overflowX: 'auto', maxHeight: '750px', overflowY: 'auto' }}>
<table className="table bordered-table mb-0">
    <thead>
        <tr>
            <th scope="col">
                <div className="form-check style-check d-flex align-items-center">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        defaultValue=""
                        id="checkAll"
                    />
                    <label className="form-check-label" htmlFor="checkAll">
                        Agency Name
                    </label>
                </div>
            </th>
            <th scope="col">Loan Id</th>
            <th scope="col">Loan Amount</th>
            <th scope="col">DPD Days</th>
    
            <th scope="col">start Date</th>
            <th scope="col">Due Date</th>

            <th scope="col">Name</th>
            <th scope="col">Mobile Number</th>
            <th scope="col">Email</th>


            <th scope="col">Action</th>
        </tr>
    </thead>
    <tbody>
    {data?.map((data,index)=>(

<tr key={index}>
<td>
<div className="form-check style-check d-flex align-items-center">
<input
    className="form-check-input"
    type="checkbox"
    defaultValue=""
    id="check1"
/>
<label className="form-check-label" htmlFor="check1">
    {data.agency_name}
</label>
</div>
</td>
<td>
<Link to="#" className="text-primary-600">
{data.loan_id}
</Link>
</td>
<td>
<div className="d-flex align-items-center">

<h6 className="text-md mb-0 fw-medium flex-grow-1">
    {data.loan_amt}
</h6>
</div>
</td>
<td>{data.defaulted}</td>

<td>{(new Date(data.start).toISOString().split('T')[0])}</td>
<td>{(new Date(data.due).toISOString().split('T')[0])}</td>
<td>{data?.student?.name}</td>
<td>{data?.student?.email}</td>


<td>
{" "}
<span className="bg-success-focus text-success-main px-24 py-4 rounded-pill fw-medium text-sm">
{data?.student?.mobile}
</span>
</td>

<td>
<Link
to="#"
className="w-32-px h-32-px  me-8 bg-primary-light text-primary-600 rounded-circle d-inline-flex align-items-center justify-content-center"
>
<Icon icon="iconamoon:eye-light" />
</Link>
<Link
to="#"
className="w-32-px h-32-px  me-8 bg-success-focus text-success-main rounded-circle d-inline-flex align-items-center justify-content-center"
>
<Icon icon="lucide:edit" />
</Link>
<Link
to="#"
className="w-32-px h-32-px  me-8 bg-danger-focus text-danger-main rounded-circle d-inline-flex align-items-center justify-content-center"
>
<Icon icon="mingcute:delete-2-line" />
</Link>
</td>
</tr>
    ))}

      
    </tbody>
</table>

</div>
<div className="d-flex flex-wrap align-items-center justify-content-between gap-2 mt-24">
            <span>
                Showing {(currentPage - 1) * 10 + 1} to {Math.min(currentPage * 10, totalPages * 10)} of {totalPages * 10} entries
            </span>
            <ul className="pagination d-flex flex-wrap align-items-center gap-2 justify-content-center">
                {/* Previous Button */}
                <li className="page-item">
                    <Link
                        className={`page-link text-secondary-light fw-medium radius-4 border-0 px-10 py-10 d-flex align-items-center justify-content-center h-32-px me-8 w-32-px ${currentPage === 1 ? 'bg-base' : ''}`}
                        to="#"
                        onClick={() => handlePageClick(currentPage - 1)}
                        disabled={currentPage === 1}
                    >
                        <Icon icon="ep:d-arrow-left" className="text-xl" />
                    </Link>
                </li>

                {/* Page Numbers */}
                {getPageNumbers().map((page, index) => (
                    <li key={index} className="page-item">
                        {/* Render page number or ellipsis */}
                        <Link
                            className={`page-link fw-medium radius-4 border-0 px-10 py-10 d-flex align-items-center justify-content-center h-32-px me-8 w-32-px ${
                                page === currentPage ? 'bg-primary-600 text-white' : 'bg-primary-50 text-secondary-light'
                            }`}
                            to="#"
                            onClick={() => page !== '...' && handlePageClick(page)}
                        >
                            {page}
                        </Link>
                    </li>
                ))}

                {/* Next Button */}
                <li className="page-item">
                    <Link
                        className={`page-link text-secondary-light fw-medium radius-4 border-0 px-10 py-10 d-flex align-items-center justify-content-center h-32-px me-8 w-32-px ${currentPage === totalPages ? 'bg-base' : ''}`}
                        to="#"
                        onClick={() => handlePageClick(currentPage + 1)}
                        disabled={currentPage === totalPages}
                    >
                        <Icon icon="ep:d-arrow-right" className="text-xl" />
                    </Link>
                </li>
            </ul>
        </div>

</div>
</>
    )}
<div
className="modal fade"
id="Modal1"
tabIndex={-1}
aria-labelledby="exampleModalLabel"
aria-hidden="true"
>
<div className="modal-dialog modal-lg modal-dialog modal-dialog-centered">
    <div className="modal-content radius-16 bg-base">
        <div className="modal-header py-16 px-24 border border-top-0 border-start-0 border-end-0">
            <h1 className="modal-title fs-5" id="exampleModalLabel">
                Auto Mapped
                           </h1>
            <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
            />
        </div>
        <div className="modal-body p-24">
            <form action="#">
                <div className="row">
                <div className="d-flex align-items-center justify-content-between border border-red-300 bg-red-50 p-2 rounded shadow-sm">
<p className="fw-semibold text-gray-500">Are you sure mapping the data:</p>
<p className="text-right text-gray-600">{total_count}</p>
</div>


                    <div className="d-flex align-items-center justify-content-center gap-3 mt-24">
                        <button
                            type="reset"
                            className="border border-danger-600 bg-hover-danger-200 text-danger-600 text-md px-40 py-11 radius-8"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="btn btn-primary border border-primary-600 text-md px-48 py-12 radius-8"
                            onClick={handlemapped}
                        >
                            Mapped
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
</div>
    </>  
    
)
}

export default Form3