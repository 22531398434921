import React from 'react'
import MasterLayout from '../masterLayout/MasterLayout'
import { Breadcrumb } from 'react-bootstrap'
import LoanJourneys from '../components/child/LoanJourneys'

const LoanJourney = () => {
  return (
    <MasterLayout>

    {/* Breadcrumb */}
    <Breadcrumb title="Loan Journey" />

    {/* TableDataLayer */}
    <LoanJourneys/>

  </MasterLayout>  
  
)
}

export default LoanJourney