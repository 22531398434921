import { Icon } from '@iconify/react/dist/iconify.js';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { json, Link } from 'react-router-dom';
import { roleSucess } from '../redux/role_access/role_access';
import config from '../config';
import FailedMessage from './child/FailedMessage';
import { Loader } from './child/Loader';

const RoleAccessLayer = () => {
    const [data, setData] = useState()
    const [datas, setDatas] = useState()
    const [selectedUsername, setSelectedUsername] = useState("");
    const [roles, setRoles] = useState([]);
    const [roleName, setRoleName] = useState()
    const [user, setUser] = useState()
    const [loading, setLoading] = useState(false); // Loading state
    const [message, setMessage] = useState('')
    const [totalPages , setTotalPages] = useState()
    const [currentPage, setCurrentPage] = useState(1)

    const user_details = useSelector((state) => state.user.currentUser)
    const [new_role ,setNewRole] = useState()
    const individual = useSelector((state)=>  state.Individual.individual_role)

    const dispatch = useDispatch()
    const PAGE_LIMIT = 5; // Number of pages to show before and after current page

    const handlePageClick = (page) => {
        if (page < 1 || page > totalPages) return;
        setCurrentPage(page)
        fetchData();
    };
    
    const getPageNumbers = () => {
        const pages = [];
        const leftLimit = Math.max(1, currentPage - Math.floor(PAGE_LIMIT / 2));
        const rightLimit = Math.min(totalPages, currentPage + Math.floor(PAGE_LIMIT / 2));
    
        if (leftLimit > 1) {
            pages.push(1);
            if (leftLimit > 2) pages.push('...'); // Ellipsis for skipped pages
        }
    
        for (let i = leftLimit; i <= rightLimit; i++) {
            pages.push(i);
        }
    
        if (rightLimit < totalPages) {
            if (rightLimit < totalPages - 1) pages.push('...'); 
            pages.push(totalPages);
        }
    
        return pages;
    };
    async function fetchData() {
        const token = localStorage.getItem('access_token')
        setLoading(true)
        const jsonData = await fetch(`${config.apiUrl}/beta/api/collection/roles?roleName=${roleName}&user_id=${user}&page=${currentPage}`,{
            method: 'GET',
            headers: {
              'Authorization': token,  // Replace with your actual token
              'Content-Type': 'application/json'  // Include this if you're sending JSON data
            },
        })
        const data = await fetch(`${config.apiUrl}/beta/api/collection/Access_data`,{
            method: 'GET',
            headers: {
              'Authorization': token,  // Replace with your actual token
              'Content-Type': 'application/json'  // Include this if you're sending JSON data
            },
        })
        if(jsonData.ok){
            setLoading(false)
        }
  

        
        const json_data = await data.json()
        setTotalPages(jsonData.totalPages)
        setDatas(json_data.user)
        
        const jsonRole = await jsonData.json()
        setData(jsonRole.result)
        // setTotalPages(Number(jsonRole.totalCount))
    }
    const [editableRow, setEditableRow] = useState(null);
    const [editedData, setEditedData] = useState({});

    const handleUsernameChange = (event) => {
        const selected = event.target.value;
        setSelectedUsername(selected);
    
        // Find the user by the selected username and update roles
        const user = datas.find((data) => data.username === selected);
        setNewRole({role_name: user.role, user_id: user._id, status: true, created_by: user_details._id})
        if (user) {
          setRoles(user.role); // Assuming 'roles' is an array in your data
        } else {
          setRoles([]); // Clear roles if no user is selected
        }
      };  
    // Handle the edit button click
    const handleEditClick = (index) => {
      setEditableRow(index);
      setEditedData({
        name: data?.[0]?.access_info[index]?.name,
        readwrite: data?.[0]?.access_info[index]?.readwrite,
        restrict: data?.[0]?.access_info[index]?.restrict,
        export_access: data?.[0]?.access_info[index]?.export_access,
        roleName: roleName,
        user_id: user,
        createdBy: user_details._id
      });
    };
  
    // Handle input change for edited fields
    const handleInputChange = (e, field) => {
      const value = e.target.value;
      // Convert "Active" to true and "Inactive" to false for boolean fields
      setEditedData((prevData) => ({
        ...prevData,
        [field]: value === 'Active'
      }));
    };
    const handleFile = async (e)=>{
        const token = localStorage.getItem('access_token')
        
        let Respones = await fetch(`${config.apiUrl}/beta/api/collection/mapped_collection?excel=true`,{
            method: 'GET',
            headers: {
              'Authorization': token,  // Replace with your actual token
              'Content-Type': 'application/json'  // Include this if you're sending JSON data
            },
        })
        const json_data = await Respones.json()

        const binaryString = window.atob(json_data.body);  
        const byteArray = new Uint8Array(binaryString.length); 
        for (let i = 0; i < binaryString.length; i++) {
          byteArray[i] = binaryString.charCodeAt(i);
        }
    
        const blob = new Blob([byteArray], { type: 'application/octet-stream' }); 
        const url = window.URL.createObjectURL(blob);
    
        const link = document.createElement('a');
        link.href = url;
        link.download = 'loan_mapping.xlsx'; 
        link.click();
    
        window.URL.revokeObjectURL(url);



    }
  
    // Save the changes (you can update the backend here)
  
    const handleClick = (newRoleName, newUserId) => {
        setUser(newUserId);    // Set the user_id to the passed value
        setRoleName(newRoleName); // Set the role_name to the passed value
    };
    const handleSaveChanges = async(index) => {
        const token = localStorage.getItem('access_token')
        setLoading(true)
        // setEditedData({roleName: roleName})
        // setEditedData({user_id:user})
        // setEditedData({createdby: user_details._id})

     const update_role = await fetch(`${config.apiUrl}/beta/api/collection/update_role`, {
        method: "PATCH",
        headers: {
          'Authorization': token,  // Replace with your actual token
          'Content-Type': 'application/json'  // Include this if you're sending JSON data
        },
        body: JSON.stringify(editedData) // Ensure you're sending a JSON string
    });
    if(update_role.ok){
        setLoading(false)
    }
    const json = await update_role.json()
    setMessage(json.message)
    if(json.message ==  "Role access updated successfully"){
        const roles_name = await fetch(`${config.apiUrl}/beta/api/collection/roles?roleName=${roleName}&user_id=${user}`,{
            method: 'GET',
            headers: {
              'Authorization': token,  // Replace with your actual token
              'Content-Type': 'application/json'  // Include this if you're sending JSON data
            },
        })
        let roles_data = await roles_name.json()
        if(roles_name.ok){
            setLoading(false)
            dispatch(roleSucess(roles_data[0].access_info))
          }
        
    }
    


  
        // After saving, exit edit mode
        setEditableRow(null);
      };
      const handleroles = async (event)=>{
        const token = localStorage.getItem('access_token')
        setLoading(true)
        const data_role = await fetch(`${config.apiUrl}/beta/api/collection/new_role`,{
            method: "POST",
            headers: {
              'Authorization': token,  // Replace with your actual token
              'Content-Type': 'application/json'  // Include this if you're sending JSON data
            },
            body: JSON.stringify(new_role)
        })
        const json_data = await data_role.json()
        if(data_role.ok ||  data_role.ok == false){
            setLoading(false)
        }
        if(json_data){
            setMessage(json_data.message)

        }
        
      }
      const handleHideMessage = () =>     setMessage(''); // Clear the message when the alert hides

    useEffect(() => {
        fetchData()

    }, [roleName, user,roles])
    return (
    <> 
        <Loader loading={loading}/>
        <>                          
              <div className="card h-100 p-0 radius-12">
                <div className="card-header border-bottom bg-base py-16 px-24 d-flex align-items-center flex-wrap gap-3 justify-content-between">
                    <div className="d-flex align-items-center flex-wrap gap-3">

                        <form className="navbar-search">
                            <input
                                type="text"
                                className="bg-base h-40-px w-auto"
                                name="search"
                                placeholder="Search"
                            />
                            <Icon icon="ion:search-outline" className="icon" />
                        </form>
                        <select className="form-select form-select-sm w-auto ps-12 py-6 radius-12 h-40-px" defaultValue="Select Status">
                            <option value="Select Status" disabled>
                                Select Status
                            </option>
                            <option value="Active">Active</option>
                            <option value="Inactive">Inactive</option>
                        </select>
                    </div>
                    
                    <div className="d-flex flex-wrap align-items-center gap-3">
                    <button
                        type="button"
                        className="btn btn-primary text-sm btn-sm px-12 py-12 radius-8 d-flex align-items-center gap-2"
                        data-bs-toggle="modal"
                        data-bs-target="#Modal1"
                    >
                        <Icon
                            icon="ic:baseline-plus"
                            className="icon text-xl line-height-1"
                        />
                        Share Access
                    </button>

                    {/* {individual?.role_access?.export_access === true && (

<button className="btn btn-sm btn-primary-600" onClick={handleFile}>
        <i className="ri-add-line" /> Download XLSX
    </button>
)} */}

</div>

                </div>
                <div className="card-body p-24">
                    <div className="table-responsive scroll-sm">
                        <table className="table bordered-table sm-table mb-0">
                            <thead>
                                <tr>

                                    <th scope="col">Create Date</th>
                                    <th scope="col">Role </th>
                                    <th scope="col">User Name </th>

                                    <th scope="col" className="text-center">
                                        Status
                                    </th>
                                    <th scope="col" className="text-center">
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody>

                                {data?.map((data, index) => (
                                    <tr key={index}>

                                        <td>{new Date(data.createdAt).toDateString()}</td>
                                        <td>{data.role_name}</td>
                                        <td>{data.role_info.username}</td>

                                        <td className="text-center">
                                            <span
                                                className={`px-24 py-4 radius-4 fw-medium text-sm ${data.status
                                                    ? "bg-success-focus text-success-600 border border-success-main"
                                                    : "bg-danger-focus text-danger-600 border border-danger-main"
                                                    }`}
                                            >
                                                {data.status ? "Active" : "Inactive"}
                                            </span>
                                        </td>
                                        <td className="text-center">
                                            <div className="d-flex align-items-center gap-10 justify-content-center">
                                                {individual?.role_access?.readwrite == true &&(
                                                <button
                                                    type="button"
                                                    className="bg-success-focus text-success-600 bg-hover-success-200 fw-medium w-40-px h-40-px d-flex justify-content-center align-items-center rounded-circle"
                                                >
                                                    <Icon data-bs-toggle="modal"
                                                        data-bs-target="#Modal2" onClick={() => handleClick(data.role_name, data.role_info._id)} icon="lucide:edit" className="menu-icon" />
                                                </button>
                                    )}
                                            </div>
                                        </td>
                                    </tr>

                                ))}

                            </tbody>
                        </table>
                    </div>
                    <div className="d-flex align-items-center justify-content-between flex-wrap gap-2 mt-24">
                    <span>
                Showing {(currentPage - 1) * 10 + 1} to {Math.min(currentPage * 10, totalPages * 10)} of {totalPages * 10} entries
            </span>                        <ul className="pagination d-flex flex-wrap align-items-center gap-2 justify-content-center">
             {/* Previous Button */}
             <li className="page-item">
                    <Link
                        className={`page-link text-secondary-light fw-medium radius-4 border-0 px-10 py-10 d-flex align-items-center justify-content-center h-32-px me-8 w-32-px ${currentPage === 1 ? 'bg-base' : ''}`}
                        to="#"
                        onClick={() => handlePageClick(currentPage - 1)}
                        disabled={currentPage === 1}
                    >
                        <Icon icon="ep:d-arrow-left" className="text-xl" />
                    </Link>
                </li>

                {/* Page Numbers */}
                {getPageNumbers().map((page, index) => (
                    <li key={index} className="page-item">
                        {/* Render page number or ellipsis */}
                        <Link
                            className={`page-link fw-medium radius-4 border-0 px-10 py-10 d-flex align-items-center justify-content-center h-32-px me-8 w-32-px ${
                                page === currentPage ? 'bg-primary-600 text-white' : 'bg-primary-50 text-secondary-light'
                            }`}
                            to="#"
                            onClick={() => page !== '...' && handlePageClick(page)}
                        >
                            {page}
                        </Link>
                    </li>
                ))}

                {/* Next Button */}
                <li className="page-item">
                    <Link
                        className={`page-link text-secondary-light fw-medium radius-4 border-0 px-10 py-10 d-flex align-items-center justify-content-center h-32-px me-8 w-32-px ${currentPage === totalPages ? 'bg-base' : ''}`}
                        to="#"
                        onClick={() => handlePageClick(currentPage + 1)}
                        disabled={currentPage === totalPages}
                    >
                        <Icon icon="ep:d-arrow-right" className="text-xl" />
                    </Link>
                </li>
                        </ul>
                    </div>
                </div>
            </div>
            {/* Modal Start */}
            <div
                className="modal fade"
                id="Modal1"
                tabIndex={-1}
                aria-labelledby="ModalLabel1"
                aria-hidden="true"
            >
                                                {message && <FailedMessage message={message} onHide={handleHideMessage} />}

                <div className="modal-dialog modal-lg modal-dialog modal-dialog-centered">
                    <div className="modal-content radius-16 bg-base">
                        <div className="modal-header py-16 px-24 border border-top-0 border-start-0 border-end-0">
                            <h1 className="modal-title fs-5" id="ModalLabel1">
                                Add New Role
                            </h1>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            />
                        </div>
                        <div className="modal-body p-24">
                            <form action="#">
                                <div className="row">
                                    <div className="col-12 mb-20">
                                        <label className="form-label fw-semibold text-primary-light text-sm mb-8">
                                            Select User
                                        </label>
                                        <select className="form-control radius-8"       value={selectedUsername}
        onChange={handleUsernameChange}>

                                        {datas?.map((data, index) => (
    <option key={index} value={data.username}>
    {data.username}
  </option>
                                        ))}
</select>
{selectedUsername && (
        <select className="form-control radius-8 mt-3">
          <option value="">Select Role</option>
            <option key={roles} value={roles}>
              {roles}
            </option>
        </select>
      )}

                                 
                                    </div>

                                    <div className="col-12 mb-20">
                                        <label className="form-label fw-semibold text-primary-light text-sm mb-8">
                                            Status{" "}
                                        </label>
                                        <div className="d-flex align-items-center flex-wrap gap-28">
                                            <div className="form-check checked-success d-flex align-items-center gap-2">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="label"
                                                    id="Personal"
                                                />
                                                <label
                                                    className="form-check-label line-height-1 fw-medium text-secondary-light text-sm d-flex align-items-center gap-1"
                                                    htmlFor="Personal"
                                                >
                                                    <span className="w-8-px h-8-px bg-success-600 rounded-circle" />
                                                    Active
                                                </label>
                                            </div>
                                            <div className="form-check checked-danger d-flex align-items-center gap-2">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="label"
                                                    id="Holiday"
                                                />
                                                <label
                                                    className="form-check-label line-height-1 fw-medium text-secondary-light text-sm d-flex align-items-center gap-1"
                                                    htmlFor="Holiday"
                                                >
                                                    <span className="w-8-px h-8-px bg-danger-600 rounded-circle" />
                                                    Inactive
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-center gap-3 mt-24">
                                        <button
                                            type="reset"
                                            className="border border-danger-600 bg-hover-danger-200 text-danger-600 text-md px-40 py-11 radius-8"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
            
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="submit"
                                            className="btn btn-primary border border-primary-600 text-md px-48 py-12 radius-8"
                                            onClick={handleroles}
                                        >
                                                                      {loading ? (
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            ) : (
                "Save"
            )}
            {loading && " Loading..."} 
                                                  </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            </>
   

            {/* Modal Start */}

            <div
                className="modal fade"
                id="Modal2"
                tabIndex={-1}
                aria-labelledby="ModalLabel2"
                aria-hidden="true"
            >
                                                {message && <FailedMessage message={message} onHide={handleHideMessage} />}

                <div className="modal-dialog modal-lg modal-dialog modal-dialog-centered">
                    <div className="modal-content radius-16 bg-base">
                        <div className="modal-header py-16 px-24 border border-top-0 border-start-0 border-end-0">
                            <h1 className="modal-title fs-5" id="ModalLabel2">
                                Edit Roles
                            </h1>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            />
                        </div>
                        <div className="modal-body p-24">
                            <form action="#">
                                <div className="row">
                                    <div className="card-body p-24">
                                        <div className="table-responsive scroll-sm">
                                            <table className="table bordered-table sm-table mb-0">
                                                <thead>
                                                    <tr>

                                                        <th scope="col">Access Name</th>
                                                        <th scope="col">Access Info </th>
                                                        <th scope="col">Restriction Info</th>

                                                        <th scope="col" className="text-center">
                                                            Export Info
                                                        </th>
                                                        <th scope="col" className="text-center">
                                                            Action
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
      {data?.[0]?.access_info?.map((access, index) => (
        <tr key={index}>
          <td>
            {editableRow === index ? (
              <input
                type="text"
                value={editedData.name}
                onChange={(e) => handleInputChange(e, 'name')}
              />
            ) : (
                access?.name
            )}
          </td>
          <td className="text-center">
            {editableRow === index ? (
              <select
                value={editedData.readwrite ? 'Active' : 'Inactive'}
                onChange={(e) => handleInputChange(e, 'readwrite')}
              >
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
              </select>
            ) : (
              <span
                className={`px-24 py-4 radius-4 fw-medium text-sm ${
                  access?.readwrite
                    ? 'bg-success-focus text-success-600 border border-success-main'
                    : 'bg-danger-focus text-danger-600 border border-danger-main'
                }`}
              >
                {access.readwrite ? 'Active' : 'Inactive'}
              </span>
            )}
          </td>
          <td className="text-center">
            {editableRow === index ? (
              <select
                value={editedData.restrict ? 'Active' : 'Inactive'}
                onChange={(e) => handleInputChange(e, 'restrict')}
              >
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
              </select>
            ) : (
              <span
                className={`px-24 py-4 radius-4 fw-medium text-sm ${
                  access?.restrict
                    ? 'bg-success-focus text-success-600 border border-success-main'
                    : 'bg-danger-focus text-danger-600 border border-danger-main'
                }`}
              >
                {access.restrict ? 'Active' : 'Inactive'}
              </span>
            )}
          </td>
          <td className="text-center">
            {editableRow === index ? (
              <select
                value={editedData.export_access ? 'Active' : 'Inactive'}
                onChange={(e) => handleInputChange(e, 'export_access')}
              >
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
              </select>
            ) : (
              <span
                className={`px-24 py-4 radius-4 fw-medium text-sm ${
                  access?.export_access
                    ? 'bg-success-focus text-success-600 border border-success-main'
                    : 'bg-danger-focus text-danger-600 border border-danger-main'
                }`}
              >
                {access.export_access ? 'Active' : 'Inactive'}
              </span>
            )}
          </td>
          <td className="text-center">
            <div className="d-flex align-items-center gap-10 justify-content-center">
              <button
                type="button"
                className="bg-success-focus text-success-600 bg-hover-success-200 fw-medium w-40-px h-40-px d-flex justify-content-center align-items-center rounded-circle"
                onClick={() => handleEditClick(index)}
              >
                <Icon icon="lucide:edit" className="menu-icon" />
              </button>
              {editableRow === index && (
                <button
                  type="button"
                  className="bg-primary text-white fw-medium w-40-px h-40-px d-flex justify-content-center align-items-center rounded-circle"
                  onClick={() => handleSaveChanges(index)}
                >
                  <Icon icon="lucide:check" className="menu-icon" />
                </button>
              )}
            </div>
          </td>
        </tr>
      ))}
    </tbody>
                                            </table>
                                        </div>

                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* Modal End */}
        </>
    )
}

export default RoleAccessLayer