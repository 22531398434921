import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts';
import { useSelector } from 'react-redux';
import config from '../../config';

const RevenueStatisticsOne = () => {
    const [selectdata , setSelectData] = useState()
    const [paid ,setPaid] = useState([])
    const [unpaid, setUnpaid] = useState([])
    
    const user = useSelector((state) => state.user.currentUser)
    async function fetchData(){
        const token = localStorage.getItem('access_token')
        const Responses = await fetch(`${config.apiUrl}/beta/api/collection/singleAgent_dashboard/${user._id}`,{
            method: 'GET',
            headers: {
              'Authorization': token,  // Replace with your actual token
              'Content-Type': 'application/json'  // Include this if you're sending JSON data
            },
        })
        const Response_month = await fetch(`${config.apiUrl}/beta/api/collection/agency_total/${user._id}`,{
            method: 'GET',
            headers: {
              'Authorization': token,  // Replace with your actual token
              'Content-Type': 'application/json'  // Include this if you're sending JSON data
            },
        })
        const json_data = await Responses.json()
        const paid_data = await Response_month.json()
        setPaid(paid_data[0].paid_sum)
        setUnpaid(paid_data[0].unpaid_sum)
        
        setSelectData(json_data?.result[0])   
     }
     useEffect(()=>{
        fetchData()
     },[])

    const useReactApexChart = () => {
        let upDownBarChartSeries = [
            {
                name: "Assigned",
                data: paid,
            },
            {
                name: "Collected",
                data: unpaid,
            },
        ]
        let upDownBarChartOptions = {
    
            chart: {
                stacked: true,
                type: "bar",
                height: 263,
                fontFamily: "Poppins, sans-serif",
                toolbar: {
                    show: false,
                },
            },
            colors: ["#487FFF", "#EF4A00"],
            plotOptions: {
                bar: {
                    columnWidth: "8",
                    borderRadius: 2,
                    borderRadiusWhenStacked: "all",
                },
            },
            stroke: {
                width: [5, 5]
            },
            dataLabels: {
                enabled: false,
            },
            legend: {
                show: true,
                position: "top",
            },
            yaxis: {
                show: false,
                title: {
                    text: undefined,
                },
                labels: {
                    formatter: function (y) {
                        return y.toFixed(0) + "";
                    },
                },
            },
            xaxis: {
                show: false,
                type: "category",
                categories: [
                    "JAN",
                    "FEB",
                    "MAR",
                    "APR",
                    "MAY",
                    "JUN",
                    "JUL",
                    "AUG",
                    "SEP",
                    "OCT",
                    "NOV",
                    "DEC"
                ],
                axisBorder: {
                    show: false,
                },
                axisTicks: {
                    show: false,
                },
                labels: {
                    show: true,
                    style: {
                        colors: "#d4d7d9",
                        fontSize: "10px",
                        fontWeight: 500,
                    },
                },
            },
            tooltip: {
                enabled: true,
                shared: true,
                intersect: false,
                theme: "dark",
                x: {
                    show: false,
                },
            },
        };
        return {upDownBarChartSeries ,upDownBarChartOptions};
    
    }
    let { upDownBarChartSeries, upDownBarChartOptions } = useReactApexChart();

  return (
    <div className="col-xxl-8">
    <div className="card h-100 radius-8 border-0">
        <div className="card-body p-24">
            <div className="d-flex align-items-center flex-wrap gap-2 justify-content-between">
                <div>
                    <h6 className="mb-2 fw-bold text-lg">Revenue Statistics</h6>
                    <span className="text-sm fw-medium text-secondary-light">
                        Yearly earning overview
                    </span>
                </div>
                {/* <div className="">
                    <select className="form-select form-select-sm w-auto bg-base border text-secondary-light" defaultValue="Yearly">
                        <option value="Yearly">Yearly</option>
                        <option value="Monthly">Monthly</option>
                        <option value="Weekly">Weekly</option>
                        <option value="Today">Today</option>
                    </select>
                </div> */}
            </div>
            <div className="mt-24 mb-24 d-flex flex-wrap">
                <div className="me-40">
                    <span className="text-secondary-light text-sm mb-1">Assigned</span>
                    <div className="">
                        <h6 className="fw-semibold d-inline-block mb-0">{selectdata?.totalSum}</h6>
                        {/* <span className="text-success-main fw-bold d-inline-flex align-items-center gap-1">
                            10%{" "}
                            <Icon
                                icon="iconamoon:arrow-up-2-fill"
                                className="icon"
                            />{" "}
                        </span> */}
                    </div>
                </div>
                <div>
                    <span className="text-secondary-light text-sm mb-1">Collected</span>
                    <div className="">
                        <h6 className="fw-semibold d-inline-block mb-0">{selectdata?.paidSum}</h6>
                        {/* <span className="text-danger-main fw-bold d-inline-flex align-items-center gap-1">
                            10%{" "}
                            <Icon
                                icon="iconamoon:arrow-down-2-fill"
                                className="icon"
                            />{" "}
                        </span> */}
                    </div>
                </div>
            </div>
            <ReactApexChart options={upDownBarChartOptions} series={upDownBarChartSeries} type="bar" height={263} id="upDownBarchart" />
        </div>
    </div>
</div>
  )
}

export default RevenueStatisticsOne