import React from 'react'
import MasterLayout from '../masterLayout/MasterLayout'
import Breadcrumb from '../components/Breadcrumb'
import LogsDetails from './LogsDetails'

const Logs = () => {
  return (
    <MasterLayout>

    {/* Breadcrumb */}
    <Breadcrumb title="log Details" />
    <LogsDetails/>
    </MasterLayout>

  )
}

export default Logs