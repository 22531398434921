import ReactApexChart from 'react-apexcharts'
import useReactApexChart from '../../hook/useReactApexChart'
import { useEffect, useState } from 'react'
import config from '../../config'
const Week = () => {
  const [loan , setloan] = useState([])
async function fetchingData(){
    const token = localStorage.getItem('access_token')
  const fetchData = await fetch(`${config.apiUrl}/beta/api/collection/Agency_growth`,{
    method: 'GET',
    headers: {
      'Authorization': token,  // Replace with your actual token
      'Content-Type': 'application/json'  // Include this if you're sending JSON data
    },
  })
  const data = await fetchData.json()
  setloan(data?.loanSums)

}
useEffect(()=>{
  fetchingData()


},[loan])
const useReactApexChart = () => {

  let columnChartSeriesTwo = [{
    name: "Sales",
    data: [{
        x: 'Jan',
        y:  (Array.isArray(loan) && loan[0] !== undefined) ? loan[0] : 0,
    }, {
        x: 'Feb',
        y:  (Array.isArray(loan) && loan[1] !== undefined) ? loan[1] : 0,
    }, {
        x: 'Mar',
        y: (Array.isArray(loan) && loan[2] !== undefined) ? loan[2] : 0,
    }, {
        x: 'Apr',
        y: (Array.isArray(loan) && loan[3] !== undefined) ? loan[3] : 0,
    }, {
        x: 'May',
        y: (Array.isArray(loan) && loan[4] !== undefined) ? loan[4] : 0,
    }, {
        x: 'Jun',
        y: (Array.isArray(loan) && loan[5] !== undefined) ? loan[5] : 0,
    }, {
        x: 'Jul',
        y: (Array.isArray(loan) && loan[6] !== undefined) ? loan[6] : 0,
    }, {
        x: 'Aug',
        y: (Array.isArray(loan) && loan[7] !== undefined) ? loan[7] : 0,
    }, {
        x: 'Sep',
        y:(Array.isArray(loan) && loan[8] !== undefined) ? loan[8] : 0,
    }, {
        x: 'Oct',
        y: (Array.isArray(loan) && loan[9] !== undefined) ? loan[9] : 0,
    }, {
        x: 'Nov',
        y: (Array.isArray(loan) && loan[10] !== undefined) ? loan[10] : 0,
    }, {
        x: 'Dec',
        y: (Array.isArray(loan) && loan[11] !== undefined) ? loan[11] : 0,
    }]
}]

let columnChartOptionsTwo = {

    chart: {
        type: 'bar',
        height: 264,
        toolbar: {
            show: false
        }
    },
    plotOptions: {
        bar: {
            horizontal: false,
            borderRadius: 8,
            borderRadiusApplication: 'end', // 'around', 'end'
            borderRadiusWhenStacked: 'last', // 'all', 'last'
            columnWidth: '23%',
            endingShape: 'rounded',
        }
    },
    dataLabels: {
        enabled: false
    },
    fill: {
        type: 'gradient',
        colors: ['#487FFF'], // Set the starting color (top color) here
        gradient: {
            shade: 'light', // Gradient shading type
            type: 'vertical',  // Gradient direction (vertical)
            shadeIntensity: 0.5, // Intensity of the gradient shading
            gradientToColors: ['#487FFF'], // Bottom gradient color (with transparency)
            inverseColors: false, // Do not invert colors
            opacityFrom: 1, // Starting opacity
            opacityTo: 1,  // Ending opacity
            stops: [0, 100],
        },
    },
    grid: {
        show: true,
        borderColor: '#D1D5DB',
        strokeDashArray: 4, // Use a number for dashed style
        position: 'back',
    },
    xaxis: {
        type: 'category',
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    },
    yaxis: {
        labels: {
            formatter: function (value) {
                return (value / 1000).toFixed(0) + 'k';
            }
        }
    },
    tooltip: {
        y: {
            formatter: function (value) {
                return value / 1000 + 'k';
            }
        }
    }
};







    return {columnChartSeriesTwo,columnChartOptionsTwo};
};
  const {columnChartSeriesTwo, columnChartOptionsTwo} = useReactApexChart()


  return (
    <div className="col-xxl-8">
    <div className="card h-100 p-0">
        <div className="card-header border-bottom bg-base py-16 px-24">
            <h6 className="text-lg fw-semibold mb-0">Year wise collection/Assigned</h6>
        </div>
        <div className="card-body p-24">
            <ReactApexChart id="columnGroupBarChart" options={columnChartOptionsTwo} series={columnChartSeriesTwo} type="bar" height={264} />
        </div>
    </div>
</div>
  )
}

export default Week