import React from 'react'
import StucredAgencies from '../masterLayout/StucredAgencies'
import Breadcrumb from '../components/Breadcrumb'
import AgentLoan from '../components/child/AgentLoan'
import StucredAgentMapped from '../components/child/StucredAgentMapped'

const StucredAgentMapping = () => {
  return (
    <StucredAgencies>

    {/* Breadcrumb */}
    <Breadcrumb title="Loan Mapping" />

    {/* TableDataLayer */}
    <StucredAgentMapped />

  </StucredAgencies>   )
}

export default StucredAgentMapping