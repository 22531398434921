import StucredAgencies from '../masterLayout/StucredAgencies'
import Breadcrumb from '../components/Breadcrumb'
import AgencyForm from '../components/child/AgencyForm'
import AgencyForm1 from '../components/child/AgencyForm1'
import React, { useEffect, useState } from 'react'
import 'datatables.net-dt/js/dataTables.dataTables.js';
import { useSelector } from 'react-redux';
import Closeloan from '../components/child/Closeloan'
import Form1 from '../components/child/Form1'
import config from '../config'
import AgencyForm3 from '../components/child/AgencyForm3'

const StucredAgenciesLoanDetails = () => {
  const [data, setData] = useState()
  const [loan, setLoan] = useState('')
  const [totalPages , setTotalPages] = useState()
  const [currentPage, setCurrentPage] = useState(1)
  
  const [activeTab, setActiveTab] = useState(1); // Default to tab 1
  const user_id = useSelector((state) => state.user.currentUser)
  console.log(user_id, "=========================")
  const PAGE_LIMIT = 5; // Number of pages to show before and after current page

  const handlePageClick = (page) => {
    if (page < 1 || page > totalPages) return;
    setCurrentPage(page)
    fetchData();
  };

  const getPageNumbers = () => {
    const pages = [];
    const leftLimit = Math.max(1, currentPage - Math.floor(PAGE_LIMIT / 2));
    const rightLimit = Math.min(totalPages, currentPage + Math.floor(PAGE_LIMIT / 2));

    if (leftLimit > 1) {
        pages.push(1);
        if (leftLimit > 2) pages.push('...'); // Ellipsis for skipped pages
    }

    for (let i = leftLimit; i <= rightLimit; i++) {
        pages.push(i);
    }

    if (rightLimit < totalPages) {
        if (rightLimit < totalPages - 1) pages.push('...'); 
        pages.push(totalPages);
    }

    return pages;
  };

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const handleOpenDialog = () => {
      setIsDialogOpen(true);
  };
  const handleCloseDialog = () => {
      setIsDialogOpen(false);
  };

  async function fetchData(){
      const Respones = await fetch(`${config.apiUrl}/beta/api/collection/mapped_collection/${user_id._id}?pages=${currentPage}`)
      const json_data = await Respones.json()
      setData(json_data.data)
      setTotalPages(json_data.totalPages)
  }

  useEffect(() => {
    // fetchData();

    // Retrieve active tab from localStorage if available
    const storedTab = localStorage.getItem('activeTab');
    if (storedTab) {
      setActiveTab(Number(storedTab)); // Convert string to number
    }
  }, []);

  const handleTabChange = (tabIndex) => {
    setActiveTab(tabIndex);
    localStorage.setItem('activeTab', tabIndex); // Save active tab to localStorage
  };

  return (
    <StucredAgencies>
      {/* Breadcrumb */}
      <Breadcrumb title="Loan Details" />

      {/* TableDataLayer */}
      <div className="card">
        <div className="card-header d-flex flex-wrap align-items-center justify-content-between gap-3">
          <div className="d-flex flex-wrap align-items-center gap-3">
            <div className="d-flex align-items-center gap-2">
              <div className="d-flex justify-content-between mb-3 gap-3">
                <button
                  className={`btn ${activeTab === 1 ? 'btn-primary' : 'btn-outline-primary'} rounded-3 px-18 py-100 gap-2`}
                  onClick={() => handleTabChange(1)}
                >
                  Pre Due
                </button>
                <button
                  className={`btn ${activeTab === 2 ? 'btn-primary' : 'btn-outline-primary'} rounded-3 px-4 py-2 ml-2`}
                  onClick={() => handleTabChange(2)}
                >
                  Defaulted
                </button>
                <button
                  className={`btn ${activeTab === 3 ? 'btn-primary' : 'btn-outline-primary'} rounded-3 px-4 py-2 gap-2`}
                  onClick={() => handleTabChange(3)}
                >
                  Closed
                </button>
              </div>
            </div>
          </div>
        </div>

        {activeTab === 1 && <AgencyForm3 />}
        {activeTab === 2 && <AgencyForm1 />}
        {activeTab === 3 && <Closeloan />}
      </div>
    </StucredAgencies>
  )
}

export default StucredAgenciesLoanDetails;
