import React, { useEffect, useState } from 'react'
import MasterLayout from '../masterLayout/MasterLayout'
import Breadcrumb from '../components/Breadcrumb'
import ViewProfileLayer from '../components/ViewProfileLayer'
import { useParams } from 'react-router-dom'

const ViewProfilePage = () => {
  const { id } = useParams();
  useEffect(()=>{
    if (id) {
      window.location.reload();
    }
  },[id])

  return (
    <>

      {/* MasterLayout */}
      <MasterLayout>

        {/* Breadcrumb */}
        <Breadcrumb title="View Profile" />

        {/* ViewProfileLayer */}
        <ViewProfileLayer />

      </MasterLayout>

    </>  
    )
}

export default ViewProfilePage