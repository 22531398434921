import { useDispatch, useSelector } from 'react-redux';
import { Outlet, Navigate } from 'react-router-dom';
import { roleFailure, roleSucess } from '../redux/role_access/role_access';
import config from '../config';
import { IndividualSuccess } from '../redux/individual_role/Individual_role';
import { useEffect, useState } from 'react';

export default function AgencyPrivateRoute() {
    const dispatch = useDispatch()
        const [isLoading, setIsLoading] = useState(false);
    
  const currentUser = useSelector((state) => state?.user?.currentUser);
      const user_role = useSelector((state)=> state.roles)

        async function fetchData(){
        
                  const roles_name = await fetch(`${config.apiUrl}/beta/api/collection/roles?roleName=${currentUser?.role}&user_id=${currentUser?._id}`)
                  let roles_data = await roles_name.json()
                      if(roles_name.ok){
                              dispatch(roleSucess(roles_data.result[0]?.access_info))
                              setIsLoading(false)
                  
                            }
                            console.log(roles_data,"============sss====sssssssss===========ssss")
                            if(roles_data.success === false){
                              dispatch(roleFailure(roles_data.message))
                              setIsLoading(false)
                  
                  
                            }
              }
              useEffect(()=>{
                  fetchData()
      
              },[])
              if(currentUser?.role == "StucredAgent" || currentUser?.role == "Agent"){
                const role = {
                  inhouse_dashboard: user_role?.currentRole.find(d => d.name === "inhouse_dashboard"),
                  loan_details: user_role?.currentRole.find(d => d.name === "loan_details"),
                  loan_mapping: user_role?.currentRole.find(d => d.name === "loan_mapping"),
                  view_profile: user_role?.currentRole.find(d => d.name === "view_profile")
                }
                dispatch(IndividualSuccess(role));
            
              }
              if(currentUser?.role == "StucredAgencies" || currentUser?.role == "Agencies"){
                const role = {
                  Stucred_lead_dashboard: user_role?.currentRole?.find(d => d.name === "Stucred_lead_dashboard"),
                  loan_details: user_role?.currentRole?.find(d => d.name === "loan_details"),
                  loan_mapping: user_role.currentRole?.find(d => d.name === "loan_mapping"),
                  agent_list: user_role?.currentRole?.find(d => d.name === "agent_list"),
                  add_agent:  user_role?.currentRole?.find(d => d.name === "add_agent"),
                  view_profile: user_role?.currentRole?.find(d => d.name === "view_profile")
                }
                dispatch(IndividualSuccess(role));
            
              }
              if(currentUser?.role ==  "SuperAdmin" || currentUser?.role == 'admin' ){
                const role = {
                  OverAll_dashboard: user_role?.currentRole?.find(d => d.name === "OverAll_dashboard"),
                  Agency_dashboard: user_role?.currentRole?.find(d => d.name === "Agency_dashboard"),
                  Inhouse_dashboard: user_role?.currentRole?.find(d => d.name === "Inhouse_dashboard"),
                  loan_mapping: user_role?.currentRole?.find(d => d.name === "loan_mapping"),
                  user_list: user_role?.currentRole?.find(d => d.name === "user_list"),
                  add_user: user_role?.currentRole?.find(d => d.name === "add_user"),
                  view_profile: user_role?.currentRole?.find(d => d.name === "view_profile"),
                  role_access: user_role?.currentRole?.find(d => d.name === "role_access"),
                  assign_role: user_role.currentRole?.find(d => d.name === "assign_role"),
                  loan_details: user_role?.currentRole?.find(d => d.name === "loan_details"),
          
              };
          
          
              // Dispatch the action to update the store
              dispatch(IndividualSuccess(role));
            
            }

  // If there's no currentUser or the role isn't admin or SuperAdmin, redirect to home
  if (!currentUser || (currentUser?.role == 'Agencies' && currentUser?.role == 'StucredAgencies')) {

    return <Navigate to="/" />;
  }

  return <Outlet />;
}
