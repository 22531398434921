import React from 'react'
import './loader.css'

export const Loader = ({loading}) => {
    if (!loading) return null; // If not loading, don't render anything

  return (
    <div className="loading-overlay">
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    </div> 
    
)
}
