import React, { useState, useEffect } from 'react';
import AgentLoan from './AgentLoan';
import Form3 from './Form3';
import AgentLoan1 from './AgentLoan1';

const StucredAgentMapped = () => {
  const [data, setData] = useState();
  const [loan, setLoan] = useState('');
  const [activeTab, setActiveTab] = useState(1); // Default to tab 1
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  // Retrieve active tab from localStorage on component mount
  useEffect(() => {
    const storedTab = localStorage.getItem('activeTab');
    if (storedTab) {
      setActiveTab(Number(storedTab)); // Convert string to number
    }
  }, []);

  // Handle tab change and store the active tab in localStorage
  const handleTabChange = (tabIndex) => {
    setActiveTab(tabIndex);
    localStorage.setItem('activeTab', tabIndex); // Save active tab to localStorage
  };

  return (
    <>
      <div className="card">
        <div className="card-header d-flex flex-wrap align-items-center justify-content-between gap-3">
          <div className="d-flex flex-wrap align-items-center gap-3">
            <div className="d-flex align-items-center gap-2">
              <div className="d-flex justify-content-between mb-3 gap-3">
                <button
                  className={`btn ${activeTab === 1 ? 'btn-primary' : 'btn-outline-primary'} rounded-3 px-18 py-100 gap-2`}
                  onClick={() => handleTabChange(1)}
                >
                  Pre Due
                </button>
                <button
                  className={`btn ${activeTab === 2 ? 'btn-primary' : 'btn-outline-primary'} rounded-3 px-4 py-2 ml-2`}
                  onClick={() => handleTabChange(2)}
                >
                  Defaulted
                </button>
              </div>
            </div>
          </div>
        </div>

        {activeTab === 1 && <AgentLoan1 />}
        {activeTab === 2 && <AgentLoan />}
      </div>
    </>
  );
};

export default StucredAgentMapped;
