import React from 'react'
import StucredAgencies from '../masterLayout/StucredAgencies'
import Breadcrumb from '../components/Breadcrumb'
import UsersListLayer from '../components/UsersListLayer'
import AgentListLayer from '../components/AgentListLayer'

const AgentList = () => {
  return (
    <>

    {/* MasterLayout */}
    <StucredAgencies>

      {/* Breadcrumb */}
      <Breadcrumb title="Agent List" />

      {/* UsersListLayer */}
      <AgentListLayer />

    </StucredAgencies>

  </>  )
}

export default AgentList