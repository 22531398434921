import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    currentRole: null,
    error: null,
    loading: false
}

const RoleAccessSlice = createSlice({
    name: "role",
    initialState,
    reducers:{
        roleStart:(state)=>{
            state.loading = true;
            state.error = null
        },
        roleSucess:(state,action)=>{
            state.currentRole = action.payload
            state.loading = false
            state.error = null
        },
        roleFailure:(state,action)=>{
            state.loading = false
            state.error = action.payload
        }
    }
})

export const {
    roleFailure,
    roleStart,
    roleSucess
} = RoleAccessSlice.actions

export default RoleAccessSlice.reducer