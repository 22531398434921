import React, { useState } from 'react'
import FailedMessage from './child/FailedMessage'
import { Link } from 'react-router-dom'
import { Icon } from '@iconify/react/dist/iconify.js'
import config from '../config'

const ResetPassword = () => {
    const [message,setMessage] = useState()
    const [formData, setFormData] = useState({})

    const [isLoading,setLoading] = useState(false)

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.id]: e.target.value.trim() });

    }
    const handleSubmit = async (e) => {
        const token = localStorage.getItem('access_token')
        const forgetPassword = localStorage.getItem('forgetPassord')
        console.log(forgetPassword,"====================")
        const Response = await fetch(`${config.apiUrl}/beta/api/collection/reset_password?token=${forgetPassword}`,{
            method: "post",
            headers: {
              'Authorization': token,  // Replace with your actual token
              'Content-Type': 'application/json'  // Include this if you're sending JSON data
            },            body: JSON.stringify(formData),


        })
    }
    const handleHideMessage = () =>     setMessage(''); // Clear the message when the alert hides

  return (
    <>
                                {message && <FailedMessage message={message} onHide={handleHideMessage} />}
                                <section className="auth bg-base d-flex flex-wrap">
    <div className="auth-left d-lg-block d-none">
        <div className="d-flex align-items-center flex-column h-100 justify-content-center">
            <img src="assets/images/login.jpg" alt="" />
        </div>
    </div>
    <div className="auth-right py-32 px-24 d-flex flex-column justify-content-center">
        <div className="max-w-464-px mx-auto w-100">
            <div>
                <Link to="/" className="mb-40 max-w-290-px">
                </Link>
                <h4 className="mb-12">Reset password to your Stucred Collection</h4>
                <p className="mb-32 text-secondary-light text-lg">
                    Hi Forget password click here to Reset
                </p>
            </div>
            <form onSubmit={handleSubmit} >
         
                <div className="icon-field mb-16">

                                 <input
                                 type="password"
                                 className="form-control h-56-px bg-neutral-50 radius-12"
                                 placeholder="Password"
                                 name='password'
                                 id='password'
                                 onChange={handleChange}
                                 
                             />
                                    
                           </div>
                           <div className="icon-field mb-16">

                 <input
                 type="password"
                 className="form-control h-56-px bg-neutral-50 radius-12"
                 placeholder="Confirm Password"
                 name='ConfirmPassword'
                 id='ConfirmPassword'
                 onChange={handleChange}
                 
             />
           </div>

      
                <div className="">
                    <div className="d-flex justify-content-between gap-2">
                        <div className="form-check style-check d-flex align-items-center">
                            <input
                                className="form-check-input border border-neutral-300"
                                type="checkbox"
                                defaultValue=""
                                id="remeber"
                                onChange={handleChange}
                            />
                                 
                            <label className="form-check-label" htmlFor="remeber">
                                Remember me{" "}
                            </label>
                        </div>
                        <Link to="/Sign-in" className="text-primary-600 fw-medium">
                            Sign in?
                        </Link>
                    </div>
                </div>
                <button
            type="submit"
            className="btn btn-primary text-sm btn-sm px-12 py-16 w-100 radius-12 mt-32"
            disabled={isLoading}
            onClick={handleSubmit}
        >
            {isLoading ? (
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            ) : (
                "Sign In"
            )}
            {isLoading && " Loading..."}
        </button>
                <div className="mt-32 center-border-horizontal text-center">
                            <span className="bg-base z-1 px-4">Or sign in with</span>
                        </div>
                        <div className=''>
                        <div className="text-sm btn-sm px-12 py-16 w-100 radius-12 mt-32">
                            <Link to={'/stucred_login'}
                                type="button"
                                className="fw-semibold text-primary-light py-16 px-12 w-100 border radius-12 text-md d-flex align-items-center justify-content-center gap-12 line-height-1 bg-hover-primary-50"
                            >
       <img
                      src="assets/images/stpng.png"
                      alt="image_user"
                      className="w-40-px h-40-px object-fit-cover rounded-circle"
                    />                                Stucred
                            </Link>
                            </div>
                       
                        </div>
                
            </form>
        </div>
    </div>
</section>
</>  )
}

export default ResetPassword