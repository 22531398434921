import React, { useEffect, useState } from 'react'

const FailedMessage = ({ message , onHide}) => {
    const [visible, setVisible] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
          setVisible(false);
          onHide(); // Notify the parent to reset any state if needed (like removing the message).
        }, 2000); // Hide the message after 10 seconds
    
        return () => clearTimeout(timer); // Cleanup timer if the component unmounts
      }, [onHide]);
  return (
    <>
<div className="alert alert-primary fixed-top" role="alert" style={centeredAlertStyle}>
<strong>{message}</strong> 
    </div>
    </> 
     )
}
const centeredAlertStyle = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1050, // Ensure it's on top of other content
    width: '80%', // Make the message responsive
    maxWidth: '600px', // Maximum width
  };
export default FailedMessage