import { BrowserRouter, Route, Routes } from "react-router-dom";
import OverAlldashboard from "./Pages/OverAlldashboard";
import Inhousedashboard from "./Pages/Inhousedashboard";
import LoanMapping from "./Pages/LoanMapping";
import LoanDetail from "./Pages/LoanDetail";
import UserListPage from "./Pages/UserListPage";
import UserGridPage from './Pages/UsersGridPage'
import AddUserPage from './Pages/AddUserPage'
import ViewProfilePage from './Pages/ViewProfilePage'
import RoleAccessPage from "./Pages/RoleAccessPage";
import AssignRolePage from "./Pages/AssignRolePage";
import StucredLoan from "./components/StucredLoan";
import StucredProfile from "./Pages/StucredProfile";
import StucredDashboard from "./Pages/StucredDashboard";
import SignInPage from "./Pages/SignInPage";
import ForgotPassword from "./Pages/ForgotPassword";
import AgencyDashboard from "./Pages/AgencyDashboard";
import StucredLogin from "./Pages/StucredLogin";
import InhouseAgencies from "./Pages/InhouseAgencies";
import StucredAgenciesLoanDetails from "./Pages/StucredAgenciesLoanDetails";
import StucredAgentMapping from "./Pages/StucredAgentMapping";
import AgentList from "./Pages/AgentList";
import AddAgent from "./Pages/AddAgent";
import ViewAgent from "./Pages/ViewAgent";
import ResetPassword from "./components/ResetPassword";
import Logs from "./Pages/Logs";
import AdminPrivateRoute from "./Routes/AdminRouter";
import PublicRoute from "./Routes/PublicRouter";
import AgencyPrivateRoute from "./Routes/AgencyRouter";
import LoanJourney from "./Pages/LoanJourney";

function App() {

  return (
    <>
    <BrowserRouter>
      <Routes>
      <Route element={<AdminPrivateRoute/>}>
      <Route path="/Inhouse" element={<OverAlldashboard />} />
      <Route path="/overall" element={<Inhousedashboard />} />
      <Route path="/Agency" element={<AgencyDashboard />} /> 
      <Route path="/loan_details" element={<LoanDetail />} /> 
      <Route path="/loan_mapping" element={<LoanMapping />} />
      <Route path="/loan_journey" element={<LoanJourney />} />

      <Route path="/users-list" element={<UserListPage />} />
      <Route path="/add-user" element={<AddUserPage />} />
      <Route path="/view-profile" element={<ViewProfilePage />} />
      <Route path="/role-access" element={<RoleAccessPage/>}/> 
      <Route path="/assign-role" element={<AssignRolePage/>}/>
      <Route exact path="/log" element={<Logs/>}/>

      </Route>
      
      <Route element={<AgencyPrivateRoute/>}>
      <Route path="/Agency_loan_details" element={<StucredAgenciesLoanDetails />} /> 
      <Route path="/Agent_mapping" element={<StucredAgentMapping />} /> 
      <Route path="/agent-list" element={<AgentList />} />
      <Route path="/add-agent" element={<AddAgent />} />
      <Route path="/agent-profile" element={<ViewAgent />} />
      <Route path="/assign-role" element={<AssignRolePage/>}/>
      <Route path="/stucred_agencies" element={<InhouseAgencies/>}/>

      </Route>

      <Route element={<AgencyPrivateRoute/>}>
      <Route path="/stucred_loan" element={<StucredLoan/>}/>
      <Route path="/stucred_dashboard" element={<StucredDashboard/>}/>
      <Route path="/stucred_profile" element={<StucredProfile/>}/>

</Route>

        <Route path="/users-grid" element={<UserGridPage />} />
        <Route exact path="/reset-password" element={<ResetPassword />} />
        <Route element={<PublicRoute/>}>

        <Route exact path="/" element={<SignInPage />} />
        </Route>
        <Route exact path="/stucred_login" element={<StucredLogin />} />

        <Route exact path="/forgot-password" element={<ForgotPassword />} />




      </Routes>
  </BrowserRouter>
  </>
  );
}

export default App;
