import React, { useEffect, useState } from 'react';
import Cashfreelink from '../components/child/Cashfreelink';
import LoanMappinglogs from '../components/child/LoanMappinglogs';
import EditUserLogs from '../components/child/EditUserLogs';
import AccessUserlogs from '../components/child/AccessUserlogs';
import ForgettPasswordDetails from '../components/child/ForgettPasswordDetails';

const LogsDetails = () => {
  const [activeTab, setActiveTab] = useState(1); // Default to tab 1

  // Fetch the saved activeTab from localStorage when the component mounts
  useEffect(() => {
    const storedTab = localStorage.getItem('activeTab');
    if (storedTab) {
      setActiveTab(Number(storedTab)); // Set the active tab if it's in localStorage
    }
  }, []);

  // Handle tab change and save the active tab to localStorage
  const handleTabChange = (tabIndex) => {
    setActiveTab(tabIndex);
    localStorage.setItem('activeTab', tabIndex);  // Save the selected tab to localStorage
  };

  return (
    <div className="card">
      <div className="card-header d-flex flex-wrap align-items-center justify-content-between gap-3">
        <div className="d-flex flex-wrap align-items-center gap-3">
          <div className="d-flex align-items-center gap-2">
            <div className="d-flex justify-content-between mb-3 gap-3">
              <button
                className={`btn ${activeTab === 1 ? 'btn-primary' : 'btn-outline-primary'} rounded-3 px-18 py-100 gap-2`}
                onClick={() => handleTabChange(1)}
              >
                Cashfree Log
              </button>
              <button
                className={`btn ${activeTab === 2 ? 'btn-primary' : 'btn-outline-primary'} rounded-3 px-4 py-2 ml-2`}
                onClick={() => handleTabChange(2)}
              >
                Forget Password logs
              </button>
              <button
                className={`btn ${activeTab === 3 ? 'btn-primary' : 'btn-outline-primary'} rounded-3 px-4 py-2 gap-2`}
                onClick={() => handleTabChange(3)}
              >
                LoanMapping logs
              </button>
              <button
                className={`btn ${activeTab === 4 ? 'btn-primary' : 'btn-outline-primary'} rounded-3 px-4 py-2 gap-2`}
                onClick={() => handleTabChange(4)}
              >
                Edit user logs
              </button>
              <button
                className={`btn ${activeTab === 5 ? 'btn-primary' : 'btn-outline-primary'} rounded-3 px-4 py-2 gap-2`}
                onClick={() => handleTabChange(5)}
              >
                Access tab logs
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Render different components based on activeTab */}
      {activeTab === 1 && <Cashfreelink />}
      {activeTab === 2 && <ForgettPasswordDetails />}
      {activeTab === 3 && <LoanMappinglogs />}
      {activeTab === 4 && <EditUserLogs />}
      {activeTab === 5 && <AccessUserlogs />}
    </div>
  );
};

export default LogsDetails;
