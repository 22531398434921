import React, { useEffect, useRef, useState } from 'react'
import $ from 'jquery';
import 'datatables.net-dt/js/dataTables.dataTables.js';
import { Icon } from '@iconify/react/dist/iconify.js';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import config from '../../config';
import { DateRangePicker } from 'react-date-range';
import ModalEditRemarks from './ModalEditRemarks';
import ModalLoan from './ModalLoan';


const AgentLoanDetails = () => {
  const [data, setData] = useState()
  const[loan, setLoan] = useState('')
  const [activeTab, setActiveTab] = useState(1);
  const [agency, setAgency] = useState()
  const [file, setFile] = useState(null); // Store the selected file in state
  const [totalData, setTotal] = useState()
  const [selection, setSelection] = useState()
  const [total_count, setTotalCount] = useState()
          const [currentPage, setCurrentPage] = useState(1)
              const [totalPages , setTotalPages] = useState()
                   const user_data = useSelector((state)=> state?.user?.currentUser)
                              const [showModal, setShowModal] = useState(false); // Controls modal visibility
                              const [showModal1, setShowModal1] = useState(false); // Controls modal visibility

                              const [selectedLoanId, setSelectedLoanId] = useState(null); // Stores the selected loan_id
                                  const [remarks, setRemarks] = useState('');
                                  const [remarksStatus, setRemarksStatus] = useState(false); 
              
  const [ID , setID] = useState()
  const user_id = useSelector((state)=> state?.user?.currentUser?._id)
  const [loan_data, setloanData] = useState()
  const individual = useSelector((state)=>  state.Individual.individual_role)
  const [openDate, setOpenDate] = useState(true)
  const [cog_id, setCog_id] = useState()
    const [showCalendar, setShowCalendar] = useState(false); // State to toggle calendar visibility
      const [formData, setFormData] = useState({});
    
  const serviceSelect = useRef(null);
  const dailySelect = useRef(null);
   const [selectionRange, setSelectionRange] = useState({
      startDate: '',
      endDate: '',
      key: "selection",
    });

  const handleSelects= (ranges) => {
    setSelectionRange(ranges.selection); // Update the selectionRange state with new date range

  };
  const handleLoanClick = (loanId) => {
    setSelectedLoanId(loanId); // Capture the clicked loan's id
    setShowModal(true);        // Show the modal
  };
  const handleLoanClick1 = (loanId) => {
    setCog_id(loanId); // Capture the clicked loan's id
    setShowModal1(true);        // Show the modal
  };

  const handleCloseModal = () => {
    setShowModal(false); // Close the modal when needed
    setSelectedLoanId(null); // Reset the selected loan ID
  };
  const handleCloseModal1 = () => {
    setShowModal1(false); // Close the modal when needed
    setSelectedLoanId(null); // Reset the selected loan ID
  };
  const handleSave = async(loanId, remarks, remarksStatus) => {
    const token = localStorage.getItem('access_token')
    console.log(`Loan ID: ${loanId}, Remarks: ${remarks}, Status: ${remarksStatus}`);
    // Perform your save action here...
    const updateResponse = await fetch('http://localhost:3000/beta/api/collection/update_remark', {
      method: 'PUT',
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        loanId,
        remarks,
        remarksStatus,
        agent_id: user_id
      }),
    });
  };

  const handleApply = () => {
    setShowCalendar(false); // Close the calendar after applying the date range
  };

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const handleOpenDialog = () => {
      setIsDialogOpen(true);
    };
    const handleCloseDialog = () => {
      setIsDialogOpen(false);
    };
    const handleFileChange = async (event) => {
      const selectedFile = event.target.files[0];
      
      // Set the file to state
      setFile(selectedFile);
  
      // Create a new FormData object
      const formData = new FormData();
      formData.append('file', selectedFile); // Append the selected file directly
  
      // Send the request to the server
      const response = await fetch(`${config.apiUrl}/beta/api/collection/upload_loan`, {
        method: 'POST',
        body: formData, // Send the form data containing the file
      });
  
      if (response.ok) {
        const result = await response.json();
      } else {
        console.error('Failed to upload file');
      }
    };
    const handlePageClick = (page) => {
        if (page < 1 || page > totalPages) return;
        setCurrentPage(page)
        fetchData();
    };
    const PAGE_LIMIT = 10
    
    const getPageNumbers = () => {
        const pages = [];
        const leftLimit = Math.max(1, currentPage - Math.floor(PAGE_LIMIT / 2));
        const rightLimit = Math.min(totalPages, currentPage + Math.floor(PAGE_LIMIT / 2));
    
        if (leftLimit > 1) {
            pages.push(1);
            if (leftLimit > 2) pages.push('...'); // Ellipsis for skipped pages
        }
    
        for (let i = leftLimit; i <= rightLimit; i++) {
            pages.push(i);
        }
    
        if (rightLimit < totalPages) {
            if (rightLimit < totalPages - 1) pages.push('...'); 
            pages.push(totalPages);
        }
    
        return pages;
    };
  async function fetchData(){
    const token = localStorage.getItem('access_token')
      const Respones = await fetch(`${config.apiUrl}/beta/api/collection/agent_loans/${user_id}?pages=${currentPage}&loan_no=${formData.loan_no}&start=${selectionRange.startDate}&end=${selectionRange.endDate}`,{
        method: 'GET',
        headers: {
          'Authorization': token,  // Replace with your actual token
          'Content-Type': 'application/json'  // Include this if you're sending JSON data
        },
  
      })
      const agency = await fetch(`${config.apiUrl}/beta/api/collection/agent/${user_id}`,{
        method: 'GET',
        headers: {
          'Authorization': token,  // Replace with your actual token
          'Content-Type': 'application/json'  // Include this if you're sending JSON data
        },
  
      })
      if(loan != 'undefined' && loan.length > 0){
        const loan_data = await fetch(`${config.apiUrl}/beta/api/collection/get_waiver/${loan}?percInterest=${Number(dailySelect.current.value)}&serperInterest=${Number(serviceSelect.current.value)}`,{
          method: 'GET',
          headers: {
            'Authorization': token,  // Replace with your actual token
            'Content-Type': 'application/json'  // Include this if you're sending JSON data
          },
      
        })
          const json_data = await loan_data.json()
          setloanData(json_data.data)
  
  
          }

      const json_datas = await agency.json()
      setAgency(json_datas.data)
      const json_data = await Respones.json()
      setData(json_data.data)
      setTotalPages(json_data.totalPages)

      
  }
  const handleSelection = async(e)=>{
      setTotal(e.target.value)
  }
  const handleAgency = async(e)=>{
      setID(e.target.value)

  }
  const handlemapped = async(e)=>{
    const token = localStorage.getItem('access_token')
      let response = await fetch(`${config.apiUrl}/beta/api/collection/UpdateAgent/${user_id}?loan_no=${totalData}&username=${ID}`,{
          method: 'PUT',
          headers: {
            'Authorization': token,  // Replace with your actual token
            'Content-Type': 'application/json'  // Include this if you're sending JSON data
          },
      })

  }
  const toggleCalendar = () => {
    setShowCalendar(!showCalendar); // Toggle the calendar visibility
  };
  const handleFile = async (e)=>{
    const token = localStorage.getItem('access_token')
      let Respones = await fetch(`${config.apiUrl}/beta/api/collection/mapped_collection?excel=true`,{
        method: 'GET',
        headers: {
          'Authorization': token,  // Replace with your actual token
          'Content-Type': 'application/json'  // Include this if you're sending JSON data
        },
      })
      const json_data = await Respones.json('')

      const binaryString = window.atob(json_data.body);  
      const byteArray = new Uint8Array(binaryString.length); 
      for (let i = 0; i < binaryString.length; i++) {
        byteArray[i] = binaryString.charCodeAt(i);
      }
  
      const blob = new Blob([byteArray], { type: 'application/octet-stream' }); 
      const url = window.URL.createObjectURL(blob);
  
      const link = document.createElement('a');
      link.href = url;
      link.download = 'loan_mapping.xlsx'; 
      link.click();
  
      window.URL.revokeObjectURL(url);



  }
  const handlecashfree = (event) =>{
      fetchData()
      const serviceValue = serviceSelect.current.value;
      const dailyValue = dailySelect.current.value;

  }
 const  handlecashfreelink = async(loan_amount, loan_id)=>{
  let postData = {
      loan_id: loan_id,
      link_amount: loan_amount
  }
  const token = localStorage.getItem('access_token')
  const response = await fetch(`${config.apiUrl}/beta/api/collection/cashfree_link`, {
      method: 'POST',               // Set the request method to POST
      headers: {
        'Authorization': token,  // Replace with your actual token
        'Content-Type': 'application/json'  // Include this if you're sending JSON data
      },
      body: JSON.stringify(postData) // Convert the data to a JSON string
  });
  }
  const handleChanges = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value.trim() });
  };
const handletotal = async(e)=>{
  const token = localStorage.getItem('access_token')
  const total_data = await fetch(`${config.apiUrl}/beta/api/collection/slab_totalLoans?days=${selection}`,{
    method: 'GET',
    headers: {
      'Authorization': token,  // Replace with your actual token
      'Content-Type': 'application/json'  // Include this if you're sending JSON data
    },
  })
  const total_json_data = await total_data.json()
  setTotalCount(total_json_data.total_document)


}
  useEffect(()=>{
      fetchData()
  },[selection,loan])
  return (
    <> 
       <div className="card-header d-flex flex-wrap align-items-center justify-content-between gap-3">



<div className="d-flex flex-wrap align-items-center gap-3">
    <div className="col-12 col-md-6 col-lg-3 mb-2">
      <label className="form-label" htmlFor="name">Name</label>
      <input
        className="form-control w-100"
        id="name"
        type="text"
        placeholder="Name"
        onChange={handleChanges}
      />
    </div>
    <div className="col-12 col-md-6 col-lg-3 mb-2">
      <label className="form-label" htmlFor="email">Email</label>
      <input
        className="form-control w-100"
        id="email"
        type="text"
        placeholder="Email"
        onChange={handleChanges}
      />
    </div>
    <div className="col-12 col-md-6 col-lg-3 mb-2">
      <label className="form-label" htmlFor="LoanId">Loan ID</label>
      <input
        className="form-control w-100"
        id="loan_no"
        type="text"
        placeholder="Loan ID"
        onChange={handleChanges}
      />
    </div>
    {/* Email Input */}
    <div className="col-12 col-md-6 col-lg-3 mb-2">
      <label className="form-label" htmlFor="LoanId">Mobile Number</label>
      <input
        className="form-control w-100"
        id="mobileNumber"
        type="text"
        placeholder="Mobile Number"
        onChange={handleChanges}
      />
    </div>



    {/* Date Range Picker */}
    <div className="col-12 col-md-6 col-lg-3 mb-2">
  <label className="form-label" htmlFor="LoanId">Due Date</label>
  {openDate && (
    <div style={{ position: "relative", width: "100%" }}>
      <button
        onClick={toggleCalendar}
        style={{
          padding: "7px 6px",
          border: "1px solid #ccc",
          borderRadius: "5px",
          width: "100%",
          textAlign: "left",
          backgroundColor: "#fff",
          cursor: "pointer",
        }}
      >
        {selectionRange.startDate && selectionRange.endDate
          ? `${selectionRange.startDate.toLocaleDateString()} - ${selectionRange.endDate.toLocaleDateString()}`
          : "Select Date Range"}
      </button>

      {showCalendar && (
        <div
          style={{
            position: "absolute",
            top: "50px",
            left: "0",
            zIndex: 999,
            backgroundColor: "#fff",
            padding: "10px",
            border: "1px solid #ccc",
            borderRadius: "5px",
          }}
        >
          <DateRangePicker
            ranges={[selectionRange]}
            onChange={handleSelects}
            showDateDisplay={false}
          />
          <div style={{ marginTop: "10px", textAlign: "center" }}>
            <button
              onClick={handleApply}
              style={{
                padding: "5px 7px",
                border: "1px solid #007BFF",
                borderRadius: "5px",
                backgroundColor: "#007BFF",
                color: "#fff",
                cursor: "pointer",
              }}
            >
              Apply
            </button>
          </div>
        </div>
      )}
    </div>
  )}

 
</div>
<div className="col-12 col-md-6 col-lg-3 mb-2">
<label>{''}</label>

<div className="row mt-2">

    <div className="col">
      <button className="btn btn-sm btn-primary w-100" onClick={fetchData}>
        <i className="ri-search-line" /> Search
      </button>
    </div>
  </div>
</div>

</div>
<div className="d-flex flex-wrap align-items-center gap-3">

{individual?.loan_details?.export_access === true && (

<button className="btn btn-sm btn-primary-600" onClick={handleFile}>
        <i className="ri-add-line" /> Download XLSX
    </button>
)}

</div>

<div className="d-flex flex-wrap align-items-center gap-3">

{individual?.loan_details?.export_access === true && (

<button className="btn btn-sm btn-primary-600" onClick={handleFile}>
        <i className="ri-add-line" /> Download XLSX
    </button>
)}

</div>

<div className="d-flex flex-wrap align-items-center gap-3">

{individual?.loan_details?.export_access === true && (

<button className="btn btn-sm btn-primary-600" onClick={handleFile}>
        <i className="ri-add-line" /> Download XLSX
    </button>
)}

</div>

</div>

<div className="card-body">
<div style={{ overflowX: 'auto', maxHeight: '750px', overflowY: 'auto' }}>
<table className="table bordered-table mb-0">
    <thead>
        <tr>
            <th scope="col">
                <div className="form-check style-check d-flex align-items-center">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        defaultValue=""
                        id="checkAll"
                    />
                    <label className="form-check-label" htmlFor="checkAll">
                        Agent Name
                    </label>
                </div>
            </th>
            <th scope="col">Loan Id</th>
            <th scope="col">Loan Amount</th>
            <th scope="col">Due Date</th>

            <th scope="col">Name</th>
            <th scope="col">Mobile Number</th>
            <th scope='col'>Email</th>
            <th scope="col">Generate Link</th>


            <th scope="col">Action</th>
        </tr>
    </thead>
    <tbody>
    {data?.map((data,index)=>(

<tr>
<td>
<div className="form-check style-check d-flex align-items-center">
<input
    className="form-check-input"
    type="checkbox"
    defaultValue=""
    id="check1"
/>
<label className="form-check-label" htmlFor="check1">
    {data.agencies_name}
</label>
</div>
</td>
<td>
<Link to="#" className="text-primary-600">
{data.loan_no}
</Link>
</td>
<td>
<div className="d-flex align-items-center">

<h6 className="text-md mb-0 fw-medium flex-grow-1">
    {data.loan_amount}
</h6>
</div>
</td>
<td>{(new Date(data.due_date).toISOString().split('T')[0])}</td>
<td>{data.defaulter_name}</td>
<td>{data.defaulter_mobile}</td>
<td>{data.defaulter_email}</td>
{/* <td>{(new Date(data.start).toISOString().split('T')[0])}</td> */}



<td>
{" "}
<span className="bg-red-focus text-red px-24 py-4 rounded-pill fw-medium text-sm">
<Icon              data-bs-toggle="modal"
        data-bs-target="#exampleModal"
icon="cil:paper-plane" style={{ fontSize: '24px', cursor: 'pointer' }} onClick={() => {
setLoan(String(data.loan_no))
}} />
</span>
</td>
<td>
<Link
to="#"
className="w-32-px h-32-px  me-8 bg-primary-light text-primary-600 rounded-circle d-inline-flex align-items-center justify-content-center"
>
<Icon icon="iconamoon:eye-light"
onClick={() => handleLoanClick1(data.cog_id)}

/>
</Link>
<Link
to="#"
className="w-32-px h-32-px  me-8 bg-success-focus text-success-main rounded-circle d-inline-flex align-items-center justify-content-center"
>
<Icon icon="lucide:edit"
onClick={() => handleLoanClick(data.loanId)}
/>
</Link>

</td>
</tr>
    ))}

      
    </tbody>
</table>


</div>
<div className="d-flex flex-wrap align-items-center justify-content-between gap-2 mt-24">
            <span>
                Showing {(currentPage - 1) * 10 + 1} to {Math.min(currentPage * 10, totalPages * 10)} of {totalPages * 10} entries
            </span>
            <ul className="pagination d-flex flex-wrap align-items-center gap-2 justify-content-center">
                {/* Previous Button */}
                <li className="page-item">
                    <Link
                        className={`page-link text-secondary-light fw-medium radius-4 border-0 px-10 py-10 d-flex align-items-center justify-content-center h-32-px me-8 w-32-px ${currentPage === 1 ? 'bg-base' : ''}`}
                        to="#"
                        onClick={() => handlePageClick(currentPage - 1)}
                        disabled={currentPage === 1}
                    >
                        <Icon icon="ep:d-arrow-left" className="text-xl" />
                    </Link>
                </li>

                {/* Page Numbers */}
                {getPageNumbers().map((page, index) => (
                    <li key={index} className="page-item">
                        {/* Render page number or ellipsis */}
                        <Link
                            className={`page-link fw-medium radius-4 border-0 px-10 py-10 d-flex align-items-center justify-content-center h-32-px me-8 w-32-px ${
                                page === currentPage ? 'bg-primary-600 text-white' : 'bg-primary-50 text-secondary-light'
                            }`}
                            to="#"
                            onClick={() => page !== '...' && handlePageClick(page)}
                        >
                            {page}
                        </Link>
                    </li>
                ))}

                {/* Next Button */}
                <li className="page-item">
                    <Link
                        className={`page-link text-secondary-light fw-medium radius-4 border-0 px-10 py-10 d-flex align-items-center justify-content-center h-32-px me-8 w-32-px ${currentPage === totalPages ? 'bg-base' : ''}`}
                        to="#"
                        onClick={() => handlePageClick(currentPage + 1)}
                        disabled={currentPage === totalPages}
                    >
                        <Icon icon="ep:d-arrow-right" className="text-xl" />
                    </Link>
                </li>
            </ul>
        </div>

</div>
<div
className="modal fade"
id="exampleModal"
tabIndex={-1}
aria-labelledby="exampleModalLabel"
aria-hidden="true"
>
<div className="modal-dialog modal-lg modal-dialog modal-dialog-centered">
    <div className="modal-content radius-16 bg-base">
        <div className="modal-header py-16 px-24 border border-top-0 border-start-0 border-end-0">
            <h1 className="modal-title fs-5" id="exampleModalLabel">
                Generate Link
            </h1>
            <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
            />
        </div>
        <div className="modal-body p-24">
            <form action="#">
            <div className="d-flex flex-wrap align-items-center gap-3 mb-5">
            <p className="fw-semibold text-gray-500">Service  %:</p>

            <select
  id="service"
  className="form-select form-select-sm w-auto"
  defaultValue="service"
  ref={serviceSelect}
  onChange={handlecashfree}
>
  <option value="service" disabled>
    Select Service charge discount
  </option>
  {user_data?.ServiecePercentage && (
    <>
      <option value={Number(user_data?.ServiecePercentage[0]?.slab1)}>
        {Number(user_data?.ServiecePercentage[0]?.slab1)}%
      </option>
      <option value={Number(user_data?.ServiecePercentage[0]?.slab2)}>
        {Number(user_data?.ServiecePercentage[0]?.slab2)}%
      </option>
    </>
  )}
</select>


    <p className="fw-semibold text-gray-500">Daily  %:</p>

    <select id='daily' className="form-select form-select-sm w-auto" defaultValue="daily" ref={dailySelect}   onChange={handlecashfree}  >
        <option value="daily" disabled>
            Select Defaulted Loans
        </option>
        {user_data?.DailyPercentage && (
    <>
      <option value={Number(user_data?.DailyPercentage[0]?.slab1)}>
        {Number(user_data?.DailyPercentage[0]?.slab1)}%
      </option>
      <option value={Number(user_data?.DailyPercentage[0]?.slab2)}>
        {Number(user_data?.DailyPercentage[0]?.slab2)}%
      </option>
    </>
  )}
    </select>
    </div>
                <div className="row">
                <div className="d-flex align-items-center justify-content-between border border-gray-300 bg-gray-50 p-2 rounded shadow-sm">
<p className="fw-semibold text-gray-500">Defaulting in days:</p>
<p className="text-right text-gray-600">{loan_data?.no_of_days_past_due}</p>
</div>
<div className="d-flex align-items-center justify-content-between border border-gray-300 bg-gray-50 p-2 rounded shadow-sm">
<p className="fw-semibold text-gray-500">Total Service Charge Fee:</p>
<p className="text-right text-gray-600">{loan_data?.serviceChargeFee}</p>
</div>
<div className="d-flex align-items-center justify-content-between border border-gray-300 bg-gray-50 p-2 rounded shadow-sm">
<p className="fw-semibold text-gray-500">Total Daily Charge Fee:</p>
<p className="text-right text-gray-600">{loan_data?.dailyLateFee}</p>
</div>
<div className="d-flex align-items-center justify-content-between border border-gray-300 bg-gray-50 p-2 rounded shadow-sm">
<p className="fw-semibold text-gray-500">Total Penalty:</p>
<p className="text-right text-gray-600">{loan_data?.totalPenalty}</p>
</div>
<div className="d-flex align-items-center justify-content-between border border-gray-300 bg-gray-50 p-2 rounded shadow-sm">
<p className="fw-semibold text-gray-500">Total Amount Payable:</p>
<p className="text-right text-gray-600">{loan_data?.totalAmountPayable}</p>
</div>
<div className="d-flex align-items-center justify-content-between border border-gray-300 bg-gray-50 p-2 rounded shadow-sm">
<p className="fw-semibold text-gray-500">Service Charge Payable:</p>
<p className="text-right text-gray-600">{loan_data?.service_waived}</p>
</div>
<div className="d-flex align-items-center justify-content-between border border-gray-300 bg-gray-50 p-2 rounded shadow-sm">
<p className="fw-semibold text-gray-500">Daily Charge Payable:</p>
<p className="text-right text-gray-600">{loan_data?.waived}</p>
</div>
<div className="d-flex align-items-center justify-content-between border border-gray-300 bg-gray-50 p-2 rounded shadow-sm">
<p className="fw-semibold text-gray-500">Total Amount to be generated:</p>
<p className="text-right text-gray-600">{loan_data?.waived_total_amount}</p>
</div>

                    <div className="d-flex align-items-center justify-content-center gap-3 mt-24">
                        <button
                            type="reset"
                            className="border border-danger-600 bg-hover-danger-200 text-danger-600 text-md px-40 py-11 radius-8"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="btn btn-primary border border-primary-600 text-md px-48 py-12 radius-8"
                            onClick={()=> handlecashfreelink(loan_data?.waived_total_amount, loan_data.loan_id)}
                        >
                            Generate
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
</div>
{showModal && (
        <ModalEditRemarks
          show={showModal}
          handleClose={handleCloseModal}
          handleSave={handleSave}
          loanId={selectedLoanId} // Pass the selected loan ID to the modal
        />
      )}

{showModal1 && (
        <ModalLoan
          show={showModal1}
          handleClose={handleCloseModal1}
          CogID={cog_id} // Pass the selected loan ID to the modal
        />
      )}
    </>   
    
)
}

export default AgentLoanDetails