import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    individual_role: null,
    error: null,
    loading: false
}

const IndividualroleSlice = createSlice({
    name: "Individual",
    initialState,
    reducers:{
        IndividualStart:(state) =>{
            state.loading = true
            state.error = null
        },
        IndividualSuccess:(state,action)=>{
            state.individual_role = action.payload
            state.loading = false
            state.error = null
        },
        IndividualFailure:(state,action)=>{
            state.loading = false
            state.error = action.payload
        },
        IndividualRemove: (state)=>{
            state.individual_role = null;
            state.error = null;
            state.loading = false;

        }
    }
})
export const {
    IndividualFailure,
    IndividualStart,
    IndividualSuccess,
    IndividualRemove
} = IndividualroleSlice.actions

export default IndividualroleSlice.reducer