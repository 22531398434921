import React from 'react'
import StucredAgencies from '../masterLayout/StucredAgencies'
import Breadcrumb from '../components/Breadcrumb'
import ViewProfileLayer from '../components/ViewProfileLayer'

const ViewAgent = () => {
  return (
    <StucredAgencies>

    {/* Breadcrumb */}
    <Breadcrumb title="View Agent" />

    {/* ViewProfileLayer */}
    <ViewProfileLayer />

  </StucredAgencies>  )
}

export default ViewAgent