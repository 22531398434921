import React from 'react'
import StucredLayout from '../masterLayout/StucredLayout'
import Breadcrumb from './Breadcrumb'
import LoanDetails from './child/LoanDetails'
import AgentLoan from './child/AgentLoan'
import AgentLoanDetails from './child/AgentLoanDetails'
import AgentloanDetails from './AgentloanDetails'

const StucredLoan = () => {
  return (
    <>
    <StucredLayout>
    <Breadcrumb title="Loan Details" />

{/* TableDataLayer */}
<AgentloanDetails />
    </StucredLayout>
    </>
  )
}

export default StucredLoan